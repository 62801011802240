import React from "react";
import FAQ from "react-faq-component";

// Define your FAQ data
const data = {
  rows: [
    {
      title: "What is this reading room management software?",
      content: "This is an easy-to-use tool that helps reading room owners manage their business better. It also helps students find and book places to study quickly and easily.",
    },
    {
      title: "How does this platform work?",
      content: "If you own a reading room, you can list it on our site, manage bookings, handle payments, and keep track of everything. If you're a student, you can search for and book a reading room that suits your needs.",
    },
    {
      title: "How do I list my reading room on the platform?",
      content: "Sign up as an owner, fill out your profile, and follow the steps to add your reading room to our site. You can upload photos, set prices, and let people know when your room is available.",
    },
    {
      title: "Can I manage multiple reading rooms with one account?",
      content: "Yes, you can manage all your reading rooms from one account. Everything is in one place for easy access.",
    },
    {
      title: "How do I get paid?",
      content: "Payments are made through secure channels and go straight to your bank account. You can keep track of all your earnings through the platform.",
    },
    {
      title: "Is my data secure?",
      content: "Yes, your information is safe with us. We use strong security measures to keep everything protected.",
    }
    // Add more questions and answers here
  ],
};

// Create the FAQ section component
const CustomFAQ = () => {
  return (
    <div className="faq-container container bg-white">
      <div className="section-head text-center mb-4">
        <h4>Frequently Asked Questions</h4>
        <p className="section-subtitle">
          When you choose us, you'll benefit from over 10 years of experience in
          Web Development, SEO.
        </p>
      </div>
      <FAQ
        data={data}
        styles={{
          titleText: "faq-question", // Apply question styles
          contentText: "faq-answer", // Apply answer styles
        }}
      />
    </div>
  );
};

export default CustomFAQ;
