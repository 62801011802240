import {
  ADD_MEMBER_DETAIL_REQUEST,
  ADD_MEMBER_DETAIL_SUCCESS,
  ADD_MEMBER_DETAIL_FAILURE,
  FETCH_MEMBER_DETAIL_REQUEST,
  FETCH_MEMBER_DETAIL_SUCCESS,
  FETCH_MEMBER_DETAIL_FAILURE,
  FETCH_ALL_MEMBER_DETAIL_REQUEST,
  FETCH_ALL_MEMBER_DETAIL_SUCCESS,
  FETCH_ALL_MEMBER_DETAIL_FAILURE,
  APPROVE_MEMBER_DETAIL_REQUEST,
  APPROVE_MEMBER_DETAIL_SUCCESS,
  APPROVE_MEMBER_DETAIL_FAILURE,
  DELETE_MEMBER_DETAIL_REQUEST,
  DELETE_MEMBER_DETAIL_SUCCESS,
  DELETE_MEMBER_DETAIL_FAILURE,
  UPDATE_MEMBER_DETAIL_REQUEST,
  UPDATE_MEMBER_DETAIL_SUCCESS,
  UPDATE_MEMBER_DETAIL_FAILURE,
} from "../../action/actionType";
import { db, auth } from "../../firebase/firebase.auth.config";
import { doc, setDoc, getDoc, collection, addDoc } from "firebase/firestore";
import toast from "react-hot-toast";

const fetchMemberDetailRequest = () => ({
  type: FETCH_MEMBER_DETAIL_REQUEST,
});

const fetchMemberDetailSuccess = (memberDetails) => ({
  type: FETCH_MEMBER_DETAIL_SUCCESS,
  payload: memberDetails,
});

const fetchMemberDetailFailure = (error) => ({
  type: FETCH_MEMBER_DETAIL_FAILURE,
  payload: error,
});

const fetchAllMemberDetailRequest = () => ({
  type: FETCH_ALL_MEMBER_DETAIL_REQUEST,
});

const fetchAllMemberDetailSuccess = (allMemberDetails) => ({
  type: FETCH_ALL_MEMBER_DETAIL_SUCCESS,
  payload: allMemberDetails,
});

const fetchAllMemberDetailFailure = (error) => ({
  type: FETCH_ALL_MEMBER_DETAIL_FAILURE,
  payload: error,
});

const addMemberDetailRequest = () => ({
  type: ADD_MEMBER_DETAIL_REQUEST,
});

const addMemberDetailSuccess = (memberDetails) => ({
  type: ADD_MEMBER_DETAIL_SUCCESS,
  payload: memberDetails,
});

const addMemberDetailFailure = (error) => ({
  type: ADD_MEMBER_DETAIL_FAILURE,
  payload: error,
});

const approveMemberDetailRequest = () => ({
  type: APPROVE_MEMBER_DETAIL_REQUEST,
});

const approveMemberDetailSuccess = (memberId) => ({
  type: APPROVE_MEMBER_DETAIL_SUCCESS,
  payload: memberId,
});

const approveMemberDetailFailure = (error) => ({
  type: APPROVE_MEMBER_DETAIL_FAILURE,
  payload: error,
});

const deleteMemberDetailRequest = () => ({
  type: DELETE_MEMBER_DETAIL_REQUEST,
});

const deleteMemberDetailSuccess = (memberDetails) => ({
  type: DELETE_MEMBER_DETAIL_SUCCESS,
  payload: memberDetails,
});

const deleteMemberDetailFailure = (error) => ({
  type: DELETE_MEMBER_DETAIL_FAILURE,
  payload: error,
});

const updateMemberDetailRequest = () => ({
  type: UPDATE_MEMBER_DETAIL_REQUEST,
});

const updateMemberDetailSuccess = (memberDetails) => ({
  type: UPDATE_MEMBER_DETAIL_SUCCESS,
  payload: memberDetails,
});

const updateMemberDetailFailure = (error) => ({
  type: UPDATE_MEMBER_DETAIL_FAILURE,
  payload: error,
});

export const fetchMemberDetailAction = (id) => async (dispatch) => {
  dispatch(fetchMemberDetailRequest());
  try {
    const userDoc = doc(db, "member", id);
    const docSnap = await getDoc(userDoc);
    const data = docSnap.data();
    dispatch(fetchMemberDetailSuccess({ ...data }));
    toast.success("Detail added successfully!");
  } catch (error) {
    dispatch(fetchMemberDetailFailure(error.message));
    toast.error("Failed to add details");
  }
};

export const fetchAllMemberDetailAction = () => async (dispatch) => {
  dispatch(fetchAllMemberDetailRequest());
  try {
    const user = auth.currentUser;
    const userDoc = doc(db, "owner", user.uid);
    const docSnap = await getDoc(userDoc);
    const data = docSnap.data();
    const res = [];
    for (let id of data.members) {
      const memberDoc = doc(db, "member", id);
      const memDocSnap = await getDoc(memberDoc);
      const memberData = memDocSnap.data();
      var sub = [];
      for (let subId of memberData.subscription) {
        const subDoc = doc(db, "subscription", subId);
        const subDocSnap = await getDoc(subDoc);
        sub.push(subDocSnap.data());
      }
      memberData.subscription = sub;
      res.push(memberData);
    }
    dispatch(fetchAllMemberDetailSuccess(res));
    toast.success("Detail added successfully!");
  } catch (error) {
    dispatch(fetchAllMemberDetailFailure(error.message));
    toast.error("Failed to add details");
  }
};

export const addMemberDetailAction =
  (memberDetails, successCb, errorCb, userUid) => async (dispatch) => {
    console.log("memberDetails", userUid);

    dispatch(addMemberDetailRequest());
    try {
      // Get owner details
      const userDoc = doc(db, "owner", userUid);
      const docSnap = await getDoc(userDoc);
      var docData = docSnap.data();

      // Create new member
      const subscriptionDetails = memberDetails.subscription[0];
      memberDetails.subscription = [];
      const docRef = await addDoc(collection(db, "member"), memberDetails);

      // Create new subscription
      subscriptionDetails.member = docRef.id;
      subscriptionDetails.library = userUid;
      const subDocRef = await addDoc(
        collection(db, "subscription"),
        subscriptionDetails
      );

      // Add subscription id to subscription
      const subDoc = doc(db, "subscription", subDocRef.id);
      const subDocSnap = await getDoc(subDoc);
      var subData = {...subDocSnap.data()};
      subData.id = subDocRef.id;
      await setDoc(subDoc, subData);

      // Push member id to owner
      if (memberDetails.approved == true) {
        docData.members.push(docRef.id);
      } else {
        docData.pending.push(docRef.id);
      }
      await setDoc(userDoc, docData);

      // Add member id to member
      const memDoc = doc(db, "member", docRef.id);
      const memDocSnap = await getDoc(memDoc);
      var memData = memDocSnap.data();
      memData.id = docRef.id;
      memData.subscription.push(subDocRef.id);
      await setDoc(memDoc, memData);

      // Dispatch success
      successCb(memberDetails);
      dispatch(addMemberDetailSuccess(memberDetails));
      toast.success("Detail added successfully!");
    } catch (error) {
      errorCb(error);
      dispatch(addMemberDetailFailure(error.message));
      toast.error("Failed to add details");
    }
  };

export const approveMemberDetailAction =
  (memberId, userId, successCb, errorCb) => async (dispatch) => {
    dispatch(approveMemberDetailRequest());
    try {
      // Get owner details
      const userDoc = doc(db, "owner", userId);
      const userDocSnap = await getDoc(userDoc);
      var userDocData = userDocSnap.data();

      // Get member details
      const memberDoc = doc(db, "member", memberId);
      const memberDocSnap = await getDoc(memberDoc);
      var memberDocData = memberDocSnap.data();

      // Move memberId from pending to members
      const index = userDocData.pending.indexOf(memberId);
      if (index > -1) {
        userDocData.pending.splice(index, 1);
        userDocData.members.push(memberId);
      }

      // Set memberDetails.approved
      memberDocData.approved = true;

      // Set them back in DB
      await setDoc(userDoc, userDocData);
      await setDoc(memberDoc, memberDocData);
      successCb();
      dispatch(approveMemberDetailSuccess(memberId));
      toast.success("Detail added successfully!");
    } catch (error) {
      errorCb(error);
      dispatch(approveMemberDetailFailure(error.message));
      toast.error("Failed to add details");
    }
  };

export const deleteMemberDetailAction =
  (memberId, userId, successCb, errorCb) => async (dispatch) => {
    dispatch(deleteMemberDetailRequest());
    try {
      // Get owner details
      const userDoc = doc(db, "owner", userId);
      const userDocSnap = await getDoc(userDoc);
      var userDocData = userDocSnap.data();

      // Remove memberId from pending and members
      const indexPending = userDocData.pending.indexOf(memberId);
      if (indexPending > -1) {
        userDocData.pending.splice(indexPending, 1);
      }
      const indexMembers = userDocData.members.indexOf(memberId);
      if (indexMembers > -1) {
        userDocData.members.splice(indexMembers, 1);
      }

      // Set User back in DB
      await setDoc(userDoc, userDocData);
      successCb();
      dispatch(deleteMemberDetailSuccess(memberId));
    } catch (error) {
      errorCb(error);
      dispatch(deleteMemberDetailFailure(error.message));
    }
  };

export const updateMemberDetailAction =
  (memberDetails, userUid, successCb, errorCb) => async (dispatch) => {
    dispatch(updateMemberDetailRequest());
    try {
      // Get member details
      const memDoc = doc(db, "member", memberDetails.id);
      console.log(memberDetails);      
      let res = [];
      for (let subDetails of memberDetails?.subscription) {
        if(subDetails?.id == undefined) {
          subDetails.member = memberDetails.id;
          subDetails.library = userUid;
          const subDocRef = await addDoc(
            collection(db, "subscription"),
            subDetails
          );
          subDetails.id = subDocRef.id;
          const subDoc = doc(db, "subscription", subDocRef.id);
          const subDocSnap = await getDoc(subDoc);
          var subData = {...subDocSnap.data()};
          subData.id = subDocRef.id;
          await setDoc(subDoc, subData);
        } else {
          const subDoc = doc(db, "subscription", subDetails.id);
          const subSnap = await getDoc(subDoc);
          var subData = subSnap.data();
          subData = subDetails;
          await setDoc(subDoc, subData);
        }
        res.push(subDetails.id);
      }
      memberDetails.subscription = res;
      await setDoc(memDoc, memberDetails);
      successCb(memberDetails);
      dispatch(updateMemberDetailSuccess(memberDetails));
      toast.success("Details updated successfully!");
    } catch (error) {
      errorCb(error);
      dispatch(updateMemberDetailFailure(error.message));
      toast.error("Failed to update details");
    }
  };
