import React from "react";
import { useSelector } from "react-redux";
import DashboardScreen from "./dashboardScreen";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const newUserDetail = useSelector((state) => state.newUserDetail);

  return (
    <div className="dashboard_all_container">
      <DashboardScreen
        loading={newUserDetail.loading}
        error={newUserDetail.error}
        student_details={newUserDetail.data.members}
        owner_details={newUserDetail.data.user}
        library_details={newUserDetail.data.library}
        pending_students={newUserDetail.data.pending}
        newUserDetail={newUserDetail}
      />
    </div>
  );
}

export default Dashboard;
