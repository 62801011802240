import React, { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ layout = "vertical", data, colors, thickness = 10 }) => {
  useEffect(() => {
    let tooltipEl = document.getElementById("chartjs-tooltip");
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      document.body.appendChild(tooltipEl);
    }
  }, []);

  const chartData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
        borderRadius: 8,
        barThickness: thickness,
      },
    ],
  };

  const options = {
    indexAxis: layout === "horizontal" ? "y" : "x",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          const tooltipModel = context.tooltip;
          const tooltipEl = document.getElementById("chartjs-tooltip");

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          const chartWidth = context.chart.width;
          const chartHeight = context.chart.height;
          const chartArea = context.chart.chartArea;
          const isHorizontalLayout = layout === "horizontal";
          const isLastBar =
            tooltipModel.dataPoints[0].dataIndex === data.length - 1;

          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.fontSize = "0.9rem";
          tooltipEl.style.pointerEvents = "none";

          if (isHorizontalLayout) {
            tooltipEl.style.left = isLastBar
              ? context.chart.canvas.offsetLeft +
                tooltipModel.caretX -
                tooltipEl.offsetWidth +
                "px"
              : context.chart.canvas.offsetLeft + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              context.chart.canvas.offsetTop + tooltipModel.caretY + "px";
          } else {
            tooltipEl.style.left = isLastBar
              ? context.chart.canvas.offsetLeft +
                tooltipModel.caretX -
                tooltipEl.offsetWidth +
                "px"
              : context.chart.canvas.offsetLeft + tooltipModel.caretX + "px";
            tooltipEl.style.top =
              context.chart.canvas.offsetTop + tooltipModel.caretY + "px";
          }

          if (tooltipModel.body) {
            const bodyLines = tooltipModel.body.map(
              (bodyItem) => bodyItem.lines
            );

            let innerHtml = `<div class="tooltip-content">
              <span class="tooltip-circle" style="background-color: ${
                colors[tooltipModel.dataPoints[0].dataIndex]
              };"></span>
              ${tooltipModel.title[0]} ${bodyLines[0][0]}
            </div>`;

            tooltipEl.innerHTML = innerHtml;
          }
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: layout === "vertical",
          borderDash: [4, 4],
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: layout === "horizontal",
          borderDash: [4, 4],
        },
      },
    },
  };

  return (
    <div className="chart-container">
      <Bar data={chartData} options={options} height={300} />
      <div id="chartjs-tooltip"></div>
    </div>
  );
};

export default BarChart;
