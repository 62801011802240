import {
  ADD_MEMBER_DETAIL_REQUEST,
  ADD_MEMBER_DETAIL_SUCCESS,
  ADD_MEMBER_DETAIL_FAILURE,
  FETCH_MEMBER_DETAIL_REQUEST,
  FETCH_MEMBER_DETAIL_SUCCESS,
  FETCH_MEMBER_DETAIL_FAILURE,
  FETCH_ALL_MEMBER_DETAIL_REQUEST,
  FETCH_ALL_MEMBER_DETAIL_SUCCESS,
  FETCH_ALL_MEMBER_DETAIL_FAILURE,
  APPROVE_MEMBER_DETAIL_REQUEST,
  APPROVE_MEMBER_DETAIL_SUCCESS,
  APPROVE_MEMBER_DETAIL_FAILURE,
  DELETE_MEMBER_DETAIL_REQUEST,
  DELETE_MEMBER_DETAIL_SUCCESS,
  DELETE_MEMBER_DETAIL_FAILURE,
  UPDATE_MEMBER_DETAIL_REQUEST,
  UPDATE_MEMBER_DETAIL_SUCCESS,
  UPDATE_MEMBER_DETAIL_FAILURE,
} from "../../action/actionType";

const initialState = {
  loading: false,
  data: {},
  error: "",
};

export const newStudentDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MEMBER_DETAIL_REQUEST:
    case FETCH_MEMBER_DETAIL_REQUEST:
    case FETCH_ALL_MEMBER_DETAIL_REQUEST:
    case APPROVE_MEMBER_DETAIL_REQUEST:
    case DELETE_MEMBER_DETAIL_REQUEST:
    case UPDATE_MEMBER_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ADD_MEMBER_DETAIL_SUCCESS:
    case UPDATE_MEMBER_DETAIL_SUCCESS:
    case APPROVE_MEMBER_DETAIL_SUCCESS:
    case DELETE_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_MEMBER_DETAIL_SUCCESS:
    case FETCH_ALL_MEMBER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case ADD_MEMBER_DETAIL_FAILURE:
    case UPDATE_MEMBER_DETAIL_FAILURE:
    case FETCH_MEMBER_DETAIL_FAILURE:
    case FETCH_ALL_MEMBER_DETAIL_FAILURE:
    case APPROVE_MEMBER_DETAIL_FAILURE:
    case DELETE_MEMBER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
