import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Button, Form as BootstrapForm } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { addMemberDetailAction } from "../action/newMember/newMemberDetailAction";
import { fetchOptionDetailAction } from "../action/newUser/newUserDetailAction";

const AddStudentQr = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newOption =
    useSelector((state) => state.newUserDetail.optionData) || [];
  const [sameAsMobile, setSameAsMobile] = useState(false);
  const { library_name, user_uid } = useParams();
  const decodedLibraryName = decodeURIComponent(library_name);

  useEffect(() => {
    dispatch(fetchOptionDetailAction(user_uid));
  }, [dispatch, user_uid]);

  // dropdown option for react select
  const getOptions = (category) =>
    (
      (Array.isArray(newOption) &&
        newOption.find((item) => item.category === category)?.options) ||
      []
    ).map((option) => ({
      label: option,
      value: option,
    }));

  const shiftOptions = getOptions("Shifts");
  const goalOptions = getOptions("Goals");
  const seatOptions = getOptions("Seats"); // Assuming you have seats in your options

  // custom style for react-select dropdown
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "48px",
      height: "48px",
      borderRadius: "8px",
      borderColor: "#ced4da",
      boxShadow: "none",
      padding: "0px 8px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "48px",
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
      paddingTop: "0px",
      paddingBottom: "0px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "48px",
    }),
  };

  const animatedComponents = makeAnimated();

  // formik initial values
  const initialValues = {
    name: "",
    mobile: "",
    whatsapp: "",
    email: "",
    gender: "",
    subscription: [
      {
        seatNo: "",
        shift: "",
        startDate: null,
        endDate: null,
        fee: "",
      },
    ],
    address: "",
    goal: "",
    memberID: "",
    approved: false,
  };

  // yup validation
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string().required("Mobile number is required"),
    whatsapp: Yup.string(),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    gender: Yup.string().required("Gender is required"),
    subscription: Yup.array().of(
      Yup.object().shape({
        seatNo: Yup.number().required("Seat number is required"),
        shift: Yup.string().required("Shift is required"),
        startDate: Yup.date()
          .nullable()
          .required("Start date is required")
          .min(
            new Date(new Date().setDate(new Date().getDate() - 15)),
            "Start date cannot be before 15 days ago"
          ),
        endDate: Yup.date()
          .nullable()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date must be later than start date"),
        fee: Yup.number(),
      })
    ),
    address: Yup.string().required("Address is required"),
    goal: Yup.string(),
    memberID: Yup.string(),
  });

  // Auto generate member id
  const generateMemberID = () => {
    const randomID = "MEM" + Math.floor(1000 + Math.random() * 9000);
    return randomID;
  };

  const formatDateToISO = (date) => {
    return date ? new Date(date).toISOString() : "";
  };

  const successCb = (data) => {
    toast.success("Submitted successfully!");
    const encodedLibraryName = encodeURIComponent(library_name);
    const encodedDataName = encodeURIComponent(data.name);

    navigate(
      `/student-form-submitted/${encodedLibraryName}/${encodedDataName}`
    );
  };

  const errorCb = (error) => {
    toast.error("Failed to submit the form");
  };

  // form submit
  const handleFormSubmit = (values) => {
    console.log("values", values);

    const formattedValues = {
      ...values,
      subscription: values.subscription.map((sub) => ({
        ...sub,
        startDate: formatDateToISO(sub.startDate),
        endDate: formatDateToISO(sub.endDate),
      })),
    };
    dispatch(
      addMemberDetailAction(formattedValues, successCb, errorCb, user_uid)
    );
  };

  return (
    <div className="container my-md-5 my-4">
      <h1 className="text-center heading_three mb-md-3 mb-2 text_gray">
        {decodedLibraryName}
      </h1>
      <h3 className="text-center heading_four mb-4 text_lightGray">
        Fill your details
      </h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ setFieldValue, errors, touched, values }) => (
          <Form className="d-flex flex-column gap-3">
            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Name
              </BootstrapForm.Label>
              <Field
                name="name"
                type="text"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter your name"
              />
              {errors.name && touched.name ? (
                <div className="text-danger">{errors.name}</div>
              ) : null}
            </BootstrapForm.Group>
            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Mobile
              </BootstrapForm.Label>
              <Field
                name="mobile"
                type="text"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter your mobile number"
                onChange={(e) => {
                  setFieldValue("mobile", e.target.value);
                  if (sameAsMobile) setFieldValue("whatsapp", e.target.value);
                }}
              />
              {errors.mobile && touched.mobile ? (
                <div className="text-danger">{errors.mobile}</div>
              ) : null}
            </BootstrapForm.Group>

            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                WhatsApp
              </BootstrapForm.Label>
              <Field
                name="whatsapp"
                type="text"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter your WhatsApp number"
              />
              <BootstrapForm.Check
                type="checkbox"
                label="Same as mobile"
                onChange={() => {
                  setSameAsMobile(!sameAsMobile);
                  if (!sameAsMobile) {
                    setFieldValue("whatsapp", values.mobile);
                  }
                }}
                checked={sameAsMobile}
              />
              {errors.whatsapp && touched.whatsapp ? (
                <div className="text-danger">{errors.whatsapp}</div>
              ) : null}
            </BootstrapForm.Group>

            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Email
              </BootstrapForm.Label>
              <Field
                name="email"
                type="email"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter your email"
              />
              {errors.email && touched.email ? (
                <div className="text-danger">{errors.email}</div>
              ) : null}
            </BootstrapForm.Group>

            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Gender
              </BootstrapForm.Label>
              <div className="d-flex gap-4">
                <label className="mb-0 d-flex gap-2">
                  <Field type="radio" name="gender" value="Male" />
                  Male
                </label>
                <label className="mb-0 d-flex gap-2">
                  <Field type="radio" name="gender" value="Female" />
                  Female
                </label>
              </div>
              {errors.gender && touched.gender ? (
                <div className="text-danger">{errors.gender}</div>
              ) : null}
            </BootstrapForm.Group>

            <div>
              <BootstrapForm.Label className="label_text w-100">
                Subscription duration
              </BootstrapForm.Label>
              <div className="d-flex flex-column flex-md-row gap-3">
                <DatePicker
                  selected={values.subscription[0].startDate}
                  onChange={(date) =>
                    setFieldValue("subscription[0].startDate", date)
                  }
                  startDate={values.subscription[0].startDate}
                  endDate={values.subscription[0].endDate}
                  minDate={
                    new Date(new Date().setDate(new Date().getDate() - 15))
                  } // Start date should be within the last 15 days
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Start Date"
                  className={`form-control input_feild ${
                    errors.subscription?.[0]?.startDate &&
                    touched.subscription?.[0]?.startDate
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <DatePicker
                  selected={values.subscription[0].endDate}
                  onChange={(date) =>
                    setFieldValue("subscription[0].endDate", date)
                  }
                  selectsEnd
                  startDate={values.subscription[0].startDate}
                  endDate={values.subscription[0].endDate}
                  minDate={values.subscription[0].startDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="End Date"
                  className={`form-control input_feild ${
                    errors.subscription?.[0]?.endDate &&
                    touched.subscription?.[0]?.endDate
                      ? "is-invalid"
                      : ""
                  }`}
                />
              </div>
            </div>
            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Shift
              </BootstrapForm.Label>
              <Select
                options={shiftOptions}
                className="formBodyControlModal"
                components={animatedComponents}
                placeholder="Select Shift"
                onChange={(option) =>
                  setFieldValue("subscription[0].shift", option.value)
                }
                styles={customStyles}
              />
              {errors.subscription?.[0]?.shift &&
              touched.subscription?.[0]?.shift ? (
                <div className="text-danger">
                  {errors.subscription[0].shift}
                </div>
              ) : null}
            </BootstrapForm.Group>

            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Seat No.
              </BootstrapForm.Label>
              <Field
                name="subscription[0].seatNo"
                type="text"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter Seat number"
              />
              {errors.subscription?.[0]?.seatNo &&
              touched.subscription?.[0]?.seatNo ? (
                <div className="text-danger">
                  {errors.subscription[0].seatNo}
                </div>
              ) : null}
            </BootstrapForm.Group>

            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Fee
              </BootstrapForm.Label>
              <Field
                name="subscription[0].fee"
                type="text"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter Fee Amount"
              />
              {errors.subscription?.[0]?.fee &&
              touched.subscription?.[0]?.fee ? (
                <div className="text-danger">{errors.subscription[0].fee}</div>
              ) : null}
            </BootstrapForm.Group>

            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Address
              </BootstrapForm.Label>
              <Field
                name="address"
                as="textarea"
                className="formBodyControlModal form-control input_feild"
                placeholder="Enter your address"
              />
              {errors.address && touched.address ? (
                <div className="text-danger">{errors.address}</div>
              ) : null}
            </BootstrapForm.Group>

            <BootstrapForm.Group>
              <BootstrapForm.Label className="label_text">
                Goal
              </BootstrapForm.Label>
              <Select
                options={goalOptions}
                className="formBodyControlModal"
                components={animatedComponents}
                placeholder="Select Goal"
                onChange={(option) => setFieldValue("goal", option.value)}
                styles={customStyles}
              />
            </BootstrapForm.Group>

            <BootstrapForm.Group className="border-bottom pb-4">
              <BootstrapForm.Label className="label_text">
                Member ID
              </BootstrapForm.Label>
              <div className="d-flex">
                <Field
                  name="memberID"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter Member ID or Generate"
                />
                <Button
                  variant="outline"
                  onClick={() => setFieldValue("memberID", generateMemberID())}
                  className="ms-2 button_design outline-none"
                >
                  Generate
                </Button>
              </div>
              {errors.memberID && touched.memberID ? (
                <div className="text-danger">{errors.memberID}</div>
              ) : null}
            </BootstrapForm.Group>

            <div className="d-flex border-0 gap-2 outline-0 justify-content-between my-2">
              <Button
                className="pri_bg button_design w-50 m-auto"
                variant="secondary"
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddStudentQr;
