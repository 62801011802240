import React, { useState, useEffect } from "react";

const LibraryFacilities = ({ setFieldValue, initialFacilities }) => {
  const defaultFacilities = ["All", "AC", "Parking", "Water", "Coffee"];

  const [selectedFacilities, setSelectedFacilities] = useState(
    Array.from(new Set([...initialFacilities]))
  );
  const [customFacilities, setCustomFacilities] = useState(
    initialFacilities.filter(
      (facility) => !defaultFacilities.includes(facility)
    )
  );
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    updateFormValues(selectedFacilities, customFacilities);
  }, [selectedFacilities, customFacilities]);

  const updateFormValues = (
    updatedSelectedFacilities,
    updatedCustomFacilities
  ) => {
    const combinedFacilities = Array.from(
      new Set(updatedSelectedFacilities.concat(updatedCustomFacilities))
    );
    setFieldValue("libraryFacilities", combinedFacilities);
  };

  const toggleFacility = (facility) => {
    let updatedSelectedFacilities;

    if (facility === "All") {
      if (selectedFacilities.length === defaultFacilities.length) {
        updatedSelectedFacilities = [];
      } else {
        updatedSelectedFacilities = defaultFacilities.filter(
          (f) => f !== "All"
        );
      }
    } else {
      if (selectedFacilities.includes(facility)) {
        updatedSelectedFacilities = selectedFacilities.filter(
          (f) => f !== facility
        );
      } else {
        updatedSelectedFacilities = [...selectedFacilities, facility];
        if (updatedSelectedFacilities.length === defaultFacilities.length - 1) {
          updatedSelectedFacilities = defaultFacilities.filter(
            (f) => f !== "All"
          );
        }
      }
    }

    setSelectedFacilities(updatedSelectedFacilities);
    updateFormValues(updatedSelectedFacilities, customFacilities);
  };

  const handleCustomFacilityAdd = (event) => {
    if (event.key === "," && inputValue.trim() !== "") {
      const newCustomFacility = inputValue.trim();
      const updatedCustomFacilities = [...customFacilities, newCustomFacility];
      const updatedSelectedFacilities = [
        ...selectedFacilities,
        newCustomFacility,
      ];

      setCustomFacilities(updatedCustomFacilities);
      setSelectedFacilities(updatedSelectedFacilities);
      setInputValue("");
      setShowInput(false);
    }
  };

  const toggleOtherInput = () => {
    setShowInput(!showInput);
  };

  return (
    <div>
      <div className="d-flex flex-wrap gap-2">
        {defaultFacilities.map((facility) => (
          <div
            key={facility}
            className={`p-2 ${
              selectedFacilities.includes(facility)
                ? "library_facilities_pri"
                : "library_facilities_sec"
            }`}
            onClick={() => toggleFacility(facility)}
            style={{ cursor: "pointer" }}
          >
            {facility}
          </div>
        ))}

        {customFacilities.map((facility) => (
          <div key={facility} className="d-flex align-items-center">
            <div
              className={`library_facilities p-2 ${
                selectedFacilities.includes(facility)
                  ? "library_facilities_pri"
                  : "library_facilities_sec"
              }`}
              onClick={() => toggleFacility(facility)}
              style={{ cursor: "pointer" }}
            >
              {facility}
            </div>
          </div>
        ))}
        <div
          className="p-2 border-secondary d-flex align-items-center pri_text"
          style={{ cursor: "pointer" }}
          onClick={toggleOtherInput}
        >
          Add other facility
          {showInput && (
            <span
              className="ms-2"
              style={{ cursor: "pointer" }}
              onClick={toggleOtherInput}
            >
              <span className="material-symbols-outlined icon_dimention">
                close
              </span>
            </span>
          )}
        </div>
        {showInput && (
          <input
            type="text"
            className="form-control input_feild"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleCustomFacilityAdd}
            placeholder="Type and press , to add facility"
          />
        )}
      </div>
    </div>
  );
};

export default LibraryFacilities;
