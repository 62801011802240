export const agGridFilterData = (
  students,
  searchTerm,
  selectedShift,
  activeStatus,
  upcomingEndDate
) => {
  const today = new Date();
  const upcomingExpiryDays = 7;

  const filtered = students.filter((student) => {
    // Filter by search term
    const matchesSearch =
      student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.memberID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.mobile.toLowerCase().includes(searchTerm.toLowerCase());

    // Filter by shift
    const matchesShift =
      selectedShift === "All" ||
      student.subscription?.some((sub) => sub.shift === selectedShift);

    // Filter by active/inactive status
    const latestSubscription = student.subscription?.reduce(
      (latest, sub) =>
        new Date(sub.endDate) > new Date(latest.endDate) ? sub : latest,
      student.subscription[0]
    );

    const isActive = latestSubscription
      ? today >= new Date(latestSubscription.startDate) &&
        today <= new Date(latestSubscription.endDate)
      : false;

    const statusMatches =
      activeStatus === "All" ||
      (activeStatus === "Active" && isActive) ||
      (activeStatus === "Inactive" && !isActive);

    // Filter by upcoming expiry
    const upcomingExpiry = latestSubscription
      ? (new Date(latestSubscription.endDate) - today) / (1000 * 60 * 60 * 24) <
          upcomingExpiryDays && today <= new Date(latestSubscription.endDate)
      : false;

    // Return if all conditions are met
    return (
      matchesSearch &&
      matchesShift &&
      statusMatches &&
      (!upcomingEndDate || upcomingExpiry)
    );
  });

  return filtered;
};
