import { combineReducers } from "redux";
import { newAdminReducer } from "./admin/adminReducer";
import { loginReducer } from "./login/loginReducer";
import { newUserDetailReducer } from "./newUser/newUserDetailReducer";
import { newLibraryDetailReducer } from "./newUser/newLibraryDetailReducer";
import { newStudentDetailReducer } from "./newMember/newMemberDetailReducer";
import { newSubscriptionDetailReducer } from "./newSubscription/newSubscriptionDetailReducer";

const rootReducer = combineReducers({
  admin: newAdminReducer,
  login: loginReducer,
  newUserDetail: newUserDetailReducer,
  newLibraryDetail: newLibraryDetailReducer,
  newStudentDetail: newStudentDetailReducer,
  newSubscriptionDetail: newSubscriptionDetailReducer,
});

export default rootReducer;
