import React from "react";
import ProfileScreen from "./profileScreen";
import { useSelector } from "react-redux";

const ProfilePage = () => {
  const newUserDetail = useSelector((state) => state.newUserDetail);
  console.log("newUserDetail", newUserDetail);

  return (
    <>
      <ProfileScreen
        owner_details={newUserDetail.data.user}
        loading={newUserDetail.loading}
        error={newUserDetail.error}
        library_details={newUserDetail.data.library}
        newUserDetail={newUserDetail.data}
      />
    </>
  );
};

export default ProfilePage;
