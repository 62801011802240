// src\screen\login\index.js
import React from "react";
import { useSelector } from "react-redux";
import LoginScreen from "./loginScreen";

const LoginForm = () => {
  const { loading, otpSent, user, error } = useSelector((state) => state.login);
  return (
    <LoginScreen
      loading={loading}
      otpSent={otpSent}
      user={user}
      error={error}
    />
  );
};

export default LoginForm;
