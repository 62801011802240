import {
  ADD_LIBRARY_DETAIL_REQUEST,
  ADD_LIBRARY_DETAIL_SUCCESS,
  ADD_LIBRARY_DETAIL_FAILURE,
} from "../../action/actionType";

const initialState = {
  loading: false,
  data: {},
  error: "",
};

export const newLibraryDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LIBRARY_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ADD_LIBRARY_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case ADD_LIBRARY_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
