import React from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Button, Form as BootstrapForm, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch } from "react-redux";
import { updateMemberDetailAction } from "../../action/newMember/newMemberDetailAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import { customStyles } from "../../util/utils";

const ModalEditStudentDetailComponent = ({
  student,
  onClose,
  dropdownData,
}) => {
  const dispatch = useDispatch();
  const userUid = sessionStorage.getItem("userUid");

  // Dropdown options for react-select
  const getOptions = (category) =>
    dropdownData
      .find((item) => item.category === category)
      ?.options.map((option) => ({
        label: option,
        value: option,
      })) || [];

  const seatOptions = getOptions("Seats");
  const shiftOptions = getOptions("Shifts");
  const goalOptions = getOptions("Goals");
  const today = new Date();

  // Custom style for react-select dropdown

  const animatedComponents = makeAnimated();
  console.log("student", student);

  // Formik initial values
  const initialValues = {
    name: student.name || "",
    mobile: student.mobile || "",
    whatsapp: student.whatsapp || "",
    email: student.email || "",
    gender: student.gender || "",
    subscription: student.subscription || [
      {
        seatNo: "",
        shift: "",
        startDate: null,
        endDate: null,
        fee: "",
        member: "",
        id: "",
        library: "",
      },
    ],
    address: student.address || "",
    goal: student.goal || "",
    memberID: student.memberID || "",
    approved: true,
    id: student.id,
  };

  // Yup validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string()
      .matches(/^\d+$/, "Mobile number must be numeric")
      .min(10, "Mobile number must be at least 10 digits")
      .max(15, "Mobile number must be at most 15 digits")
      .required("Mobile number is required"),
    whatsapp: Yup.string()
      .matches(/^\d+$/, "WhatsApp number must be numeric")
      .min(10, "WhatsApp number must be at least 10 digits")
      .max(15, "WhatsApp number must be at most 15 digits")
      .notRequired(),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    gender: Yup.string().required("Gender is required"),
    subscription: Yup.array().of(
      Yup.object().shape({
        seatNo: Yup.string().required("Seat number is required"),
        shift: Yup.string().required("Shift is required"),
        startDate: Yup.date().required("Start date is required"),
        endDate: Yup.date()
          .required("End date is required")
          .min(Yup.ref("startDate"), "End date must be after start date"),
        fee: Yup.number().required("Fee is required"),
      })
    ),
    address: Yup.string().required("Address is required"),
    goal: Yup.string(),
    memberID: Yup.string(),
  });

  // Convert the date to ISO format string for backend submission
  const formatDateToISO = (date) => {
    return date ? new Date(date).toISOString() : "";
  };

  // Form submit handler
  const handleFormSubmit = (values) => {
    const successCb = (data) => {
      dispatch(fetchUserDetailAction());
      onClose();
    };
    const errorCb = (error) => {
      console.log(error);
    };

    const formattedValues = {
      ...values,
      subscription: values.subscription.map((sub) => ({
        ...sub,
        startDate: formatDateToISO(sub.startDate),
        endDate: formatDateToISO(sub.endDate),
      })),
    };
    dispatch(
      updateMemberDetailAction(formattedValues, userUid, successCb, errorCb)
    );
  };

  const handleContinue = (push, prevSub) => {
    const newStartDate = new Date(prevSub.endDate);
    newStartDate.setDate(newStartDate.getDate() + 1);

    const newEndDate = new Date(newStartDate);
    newEndDate.setMonth(newEndDate.getMonth() + 1);

    push({
      seatNo: prevSub.seatNo,
      shift: prevSub.shift,
      startDate: newStartDate,
      endDate: newEndDate,
      fee: prevSub.fee,
    });
  };

  const handleOneMonthFromToday = (push) => {
    const newStartDate = new Date(today);

    const newEndDate = new Date(today);
    newEndDate.setMonth(newEndDate.getMonth() + 2);

    push({
      seatNo: "",
      shift: "",
      startDate: newStartDate,
      endDate: newEndDate,
      fee: "",
    });
  };

  const handleFillPreviousData = (push, prevSub) => {
    push({
      seatNo: prevSub.seatNo,
      shift: prevSub.shift,
      startDate: null,
      endDate: null,
      fee: prevSub.fee,
    });
  };

  return (
    <Modal onHide={onClose} show={true} centered>
      <Modal.Header closeButton>
        <Modal.Title>Edit Student</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <Form className="row gap-3">
              {/* Basic Details Form Fields */}
              <BootstrapForm.Group className="col-12">
                <p className="fw-semibold text_black col-12 px-0 mb-2 mt-0">
                  Basic Details
                </p>
                <BootstrapForm.Label className="label_text col-12 px-0">
                  Name
                </BootstrapForm.Label>
                <Field
                  name="name"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your name"
                />
                {errors.name && touched.name ? (
                  <div className="text-danger">{errors.name}</div>
                ) : null}
              </BootstrapForm.Group>
              <BootstrapForm.Group className="col-12">
                <BootstrapForm.Label className="label_text">
                  Mobile
                </BootstrapForm.Label>
                <Field
                  name="mobile"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your mobile number"
                />
                {errors.mobile && touched.mobile ? (
                  <div className="text-danger">{errors.mobile}</div>
                ) : null}
              </BootstrapForm.Group>
              <BootstrapForm.Group className="col-12">
                <BootstrapForm.Label className="label_text">
                  WhatsApp
                </BootstrapForm.Label>
                <Field
                  name="whatsapp"
                  type="text"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your WhatsApp number"
                />
                {errors.whatsapp && touched.whatsapp ? (
                  <div className="text-danger">{errors.whatsapp}</div>
                ) : null}
              </BootstrapForm.Group>
              <BootstrapForm.Group className="col-12">
                <BootstrapForm.Label className="label_text">
                  Email
                </BootstrapForm.Label>
                <Field
                  name="email"
                  type="email"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your email"
                />
                {errors.email && touched.email ? (
                  <div className="text-danger">{errors.email}</div>
                ) : null}
              </BootstrapForm.Group>
              <BootstrapForm.Group className="col-12">
                <BootstrapForm.Label className="label_text">
                  Gender
                </BootstrapForm.Label>
                <div className="d-flex gap-4">
                  <label className="mb-0 d-flex gap-2">
                    <Field type="radio" name="gender" value="Male" />
                    Male
                  </label>
                  <label className="mb-0 d-flex gap-2">
                    <Field type="radio" name="gender" value="Female" />
                    Female
                  </label>
                </div>
                {errors.gender && touched.gender ? (
                  <div className="text-danger">{errors.gender}</div>
                ) : null}
              </BootstrapForm.Group>
              <BootstrapForm.Group className="col-12">
                <BootstrapForm.Label className="label_text">
                  Address
                </BootstrapForm.Label>
                <Field
                  name="address"
                  as="textarea"
                  className="formBodyControlModal form-control input_feild"
                  placeholder="Enter your address"
                />
                {errors.address && touched.address ? (
                  <div className="text-danger">{errors.address}</div>
                ) : null}
              </BootstrapForm.Group>
              <BootstrapForm.Group className="col-12">
                <BootstrapForm.Label className="label_text">
                  Goal
                </BootstrapForm.Label>
                <Select
                  options={goalOptions}
                  className="formBodyControlModal"
                  components={animatedComponents}
                  placeholder="Select Goal"
                  onChange={(option) => setFieldValue("goal", option.value)}
                  styles={customStyles}
                  value={goalOptions?.find(
                    (option) => option.value === values.goal
                  )}
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group className="col-12 border-bottom pb-4">
                <BootstrapForm.Label className="label_text">
                  Member ID
                </BootstrapForm.Label>
                <div className="d-flex">
                  <Field
                    name="memberID"
                    type="text"
                    className="formBodyControlModal form-control input_feild"
                    placeholder="Enter Member ID or Generate"
                  />
                </div>
                {errors.memberID && touched.memberID ? (
                  <div className="text-danger">{errors.memberID}</div>
                ) : null}
              </BootstrapForm.Group>

              {/* Subscription Details */}
              <BootstrapForm.Group className="col-12">
                <BootstrapForm.Label className="fw-semibold mt-3 text_black mb-2">
                  Subscription Details
                </BootstrapForm.Label>
                <FieldArray name="subscription">
                  {({ insert, remove, push }) => (
                    <div>
                      {values.subscription.map((sub, index) => (
                        <div
                          key={index}
                          className="mb-3 border pri_radious py-3 d-flex flex-column gap-2"
                        >
                          <div className="d-flex align-items-center justify-content-between px-3">
                            <p className="fw-semibold mb-2">
                              Subscription - {index + 1}
                            </p>
                            <button
                              className="border-0 bg-transparent d-flex align-items-center gap-1"
                              onClick={() => remove(index)}
                            >
                              <span className="material-symbols-outlined icon_dimention">
                                Delete
                              </span>
                              <p className="table_row_cell text-nowrap mb-0">
                                delete
                              </p>
                            </button>
                          </div>
                          <BootstrapForm.Group className="col-12">
                            <BootstrapForm.Label className="label_text w-100">
                              Start Date
                            </BootstrapForm.Label>
                            <DatePicker
                              selected={sub.startDate}
                              onChange={(date) =>
                                setFieldValue(
                                  `subscription[${index}].startDate`,
                                  date
                                )
                              }
                              selectsStart
                              startDate={sub.startDate}
                              endDate={sub.endDate}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="Start Date"
                              className={`form-control input_feild ${
                                errors.subscription?.[index]?.startDate &&
                                touched.subscription?.[index]?.startDate
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                          </BootstrapForm.Group>
                          <BootstrapForm.Group className="col-12">
                            <BootstrapForm.Label className="label_text w-100">
                              End Date
                            </BootstrapForm.Label>
                            <DatePicker
                              selected={sub.endDate}
                              onChange={(date) =>
                                setFieldValue(
                                  `subscription[${index}].endDate`,
                                  date
                                )
                              }
                              selectsEnd
                              startDate={sub.startDate}
                              endDate={sub.endDate}
                              minDate={sub.startDate}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="End Date"
                              className={`form-control input_feild ${
                                errors.subscription?.[index]?.endDate &&
                                touched.subscription?.[index]?.endDate
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                          </BootstrapForm.Group>
                          <BootstrapForm.Group className="col-12">
                            <BootstrapForm.Label className="label_text">
                              Shift
                            </BootstrapForm.Label>
                            <Select
                              options={shiftOptions}
                              className="formBodyControlModal"
                              components={animatedComponents}
                              placeholder="Select Shift"
                              onChange={(option) =>
                                setFieldValue(
                                  `subscription[${index}].shift`,
                                  option?.value || ""
                                )
                              }
                              value={
                                shiftOptions.find(
                                  (option) => option.value === sub.shift
                                ) || null
                              }
                              styles={customStyles}
                            />
                          </BootstrapForm.Group>
                          <BootstrapForm.Group className="col-12">
                            <BootstrapForm.Label className="label_text">
                              Seat No
                            </BootstrapForm.Label>
                            <Field
                              name={`subscription[${index}].seatNo`}
                              type="number"
                              className={`form-control input_feild ${
                                errors.subscription?.[index]?.seatNo &&
                                touched.subscription?.[index]?.seatNo
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter Seat No."
                            />
                            {errors.subscription?.[index]?.seatNo &&
                            touched.subscription?.[index]?.seatNo ? (
                              <div className="text-danger">
                                {errors.subscription[index].seatNo}
                              </div>
                            ) : null}
                          </BootstrapForm.Group>

                          <BootstrapForm.Group className="col-12">
                            <BootstrapForm.Label className="label_text">
                              Fee
                            </BootstrapForm.Label>
                            <Field
                              name={`subscription[${index}].fee`}
                              type="number"
                              className={`form-control input_feild ${
                                errors.subscription?.[index]?.fee &&
                                touched.subscription?.[index]?.fee
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                          </BootstrapForm.Group>
                        </div>
                      ))}

                      <button
                        className="border-0 bg-transparent d-flex align-items-center gap-1 "
                        onClick={() =>
                          push({
                            seatNo: "",
                            shift: "",
                            startDate: null,
                            endDate: null,
                            fee: "",
                          })
                        }
                      >
                        <span className="material-symbols-outlined icon_dimention">
                          add
                        </span>
                        <p className="table_row_cell text-nowrap mb-0">
                          Add subscription
                        </p>
                      </button>

                      <div className="d-flex gap-2 mt-3 mb-3 justify-content-between">
                        <Button
                          variant="primary"
                          className="pri_radious btn-success border-none border-0 mt-0 me-2 select_duration_btn"
                          onClick={() =>
                            handleContinue(
                              push,
                              values.subscription[
                                values.subscription.length - 1
                              ]
                            )
                          }
                        >
                          Continue
                        </Button>
                        <Button
                          className="pri_radious border-none border-0 mt-0 me-2 select_duration_btn"
                          variant="secondary"
                          onClick={() => handleOneMonthFromToday(push)}
                        >
                          1 Month from Today
                        </Button>
                        <Button
                          className="pri_radious btn-success border-none border-0 mt-0 me-2 select_duration_btn"
                          variant="info"
                          onClick={() =>
                            handleFillPreviousData(
                              push,
                              values.subscription[
                                values.subscription.length - 1
                              ]
                            )
                          }
                        >
                          Use previous data for new sub
                        </Button>
                      </div>
                    </div>
                  )}
                </FieldArray>
              </BootstrapForm.Group>

              {/* Form Submit Button */}
              <div className="col-12 text-center border-top pt-2">
                <div className="col-12 d-flex border-0 gap-2 outline-0 justify-content-between my-2">
                  <Button
                    className="button_design w-50"
                    variant="outline"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="pri_bg button_design w-50"
                    variant="secondary"
                    type="submit"
                  >
                    Update Student
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ModalEditStudentDetailComponent;
