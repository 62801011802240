// AUTHENTICATION-------------
// Send Otp
export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

// Verify Otp
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

// Logout
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

// verification
export const AUTH_CHECK_REQUEST = "AUTH_CHECK_REQUEST";
export const AUTH_CHECK_SUCCESS = "AUTH_CHECK_SUCCESS";
export const AUTH_CHECK_FAILURE = "AUTH_CHECK_FAILURE";

// OWNER-----------
// User Detail Fetch
export const FETCH_USER_DETAIL_REQUEST = "FETCH_USER_DETAIL_REQUEST";
export const FETCH_USER_DETAIL_SUCCESS = "FETCH_USER_DETAIL_SUCCESS";
export const FETCH_USER_DETAIL_FAILURE = "FETCH_USER_DETAIL_FAILURE";

// Options Fetch
export const FETCH_OPTION_DETAIL_REQUEST = "FETCH_OPTION_DETAIL_REQUEST";
export const FETCH_OPTION_DETAIL_SUCCESS = "FETCH_OPTION_DETAIL_SUCCESS";
export const FETCH_OPTION_DETAIL_FAILURE = "FETCH_OPTION_DETAIL_FAILURE";

// User Detail Add
export const ADD_USER_DETAIL_REQUEST = "ADD_USER_DETAIL_REQUEST";
export const ADD_USER_DETAIL_SUCCESS = "ADD_USER_DETAIL_SUCCESS";
export const ADD_USER_DETAIL_FAILURE = "ADD_USER_DETAIL_FAILURE";

// User Library Add
export const ADD_LIBRARY_DETAIL_REQUEST = "ADD_LIBRARY_DETAIL_REQUEST";
export const ADD_LIBRARY_DETAIL_SUCCESS = "ADD_LIBRARY_DETAIL_SUCCESS";
export const ADD_LIBRARY_DETAIL_FAILURE = "ADD_LIBRARY_DETAIL_FAILURE";

// MEMBER----------
// Member Fetch
export const FETCH_MEMBER_DETAIL_REQUEST = "FETCH_MEMBER_DETAIL_REQUEST";
export const FETCH_MEMBER_DETAIL_SUCCESS = "FETCH_MEMBER_DETAIL_SUCCESS";
export const FETCH_MEMBER_DETAIL_FAILURE = "FETCH_MEMBER_DETAIL_FAILURE";

// Member Add
export const ADD_MEMBER_DETAIL_REQUEST = "ADD_MEMBER_DETAIL_REQUEST";
export const ADD_MEMBER_DETAIL_SUCCESS = "ADD_MEMBER_DETAIL_SUCCESS";
export const ADD_MEMBER_DETAIL_FAILURE = "ADD_MEMBER_DETAIL_FAILURE";

// Member Update
export const UPDATE_MEMBER_DETAIL_REQUEST = "UPDATE_MEMBER_DETAIL_REQUEST";
export const UPDATE_MEMBER_DETAIL_SUCCESS = "UPDATE_MEMBER_DETAIL_SUCCESS";
export const UPDATE_MEMBER_DETAIL_FAILURE = "UPDATE_MEMBER_DETAIL_FAILURE";

// Fetch All Members
export const FETCH_ALL_MEMBER_DETAIL_REQUEST =
  "FETCH_ALL_MEMBER_DETAIL_REQUEST";
export const FETCH_ALL_MEMBER_DETAIL_SUCCESS =
  "FETCH_ALL_MEMBER_DETAIL_SUCCESS";
export const FETCH_ALL_MEMBER_DETAIL_FAILURE =
  "FETCH_ALL_MEMBER_DETAIL_FAILURE";

// Member Approve
export const APPROVE_MEMBER_DETAIL_REQUEST = "APPROVE_MEMBER_DETAIL_REQUEST";
export const APPROVE_MEMBER_DETAIL_SUCCESS = "APPROVE_MEMBER_DETAIL_SUCCESS";
export const APPROVE_MEMBER_DETAIL_FAILURE = "APPROVE_MEMBER_DETAIL_FAILURE";

// Member Delete
export const DELETE_MEMBER_DETAIL_REQUEST = "DELETE_MEMBER_DETAIL_REQUEST";
export const DELETE_MEMBER_DETAIL_SUCCESS = "DELETE_MEMBER_DETAIL_SUCCESS";
export const DELETE_MEMBER_DETAIL_FAILURE = "DELETE_MEMBER_DETAIL_FAILURE";

// Subscription Add
export const ADD_SUBSCRIPTION_DETAIL_REQUEST =
  "ADD_SUBSCRIPTION_DETAIL_REQUEST";
export const ADD_SUBSCRIPTION_DETAIL_SUCCESS =
  "ADD_SUBSCRIPTION_DETAIL_SUCCESS";
export const ADD_SUBSCRIPTION_DETAIL_FAILURE =
  "ADD_SUBSCRIPTION_DETAIL_FAILURE";

// Admin
export const APPROVE_ADMIN_REQUEST = "APPROVE_ADMIN_REQUEST";
export const APPROVE_ADMIN_SUCCESS = "APPROVE_ADMIN_SUCCESS";
export const APPROVE_ADMIN_FAILURE = "APPROVE_ADMIN_FAILURE";

export const REJECT_ADMIN_REQUEST = "REJECT_ADMIN_REQUEST";
export const REJECT_ADMIN_SUCCESS = "REJECT_ADMIN_SUCCESS";
export const REJECT_ADMIN_FAILURE = "REJECT_ADMIN_FAILURE";
