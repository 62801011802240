import React, { Fragment } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";

const testimonials = [
  {
    author: {
      fullName: "Akshay Kumar",
      picture: "https://cdn.easyfrontend.com/pictures/users/user2.jpg",
      designation: "Owner of The Study Haven, Mumbai",
    },
    rating: 4.5,
    description: "This software revolutionized our management with easy booking, real-time analytics, and increased repeat customers."
  },
  {
    author: {
      fullName: "Raima Sen",
      picture: "https://cdn.easyfrontend.com/pictures/users/user3.jpg",
      designation: "Owner of Knowledge Nest, Delhi",
    },
    rating: 3.8,
    description: "Managing multiple rooms is now simple with this platform’s unified dashboard for attendance and payments, plus excellent support."
    },
  {
    author: {
      fullName: "Arjun Kapur",
      picture: "https://cdn.easyfrontend.com/pictures/users/user27.jpg",
      designation: "Owner of Study Sphere, Pune",
    },
    rating: 4.0,
    description: "Managing bookings and feedback in one place led to a 25% membership boost and valuable insights."
  }
];

const Rating = ({ rating }) => (
  <p className="mb-2">
    {[...Array(5)].map((_, i) => {
      const index = i + 1;
      return (
        // <Fragment key={i}>
        //   {index <= Math.floor(rating) ? (
        //     <FontAwesomeIcon icon={faStar} />
        //   ) : rating > i && rating < index + 1 ? (
        //     <FontAwesomeIcon icon={faStarHalfAlt} />
        //   ) : (
        //     <FontAwesomeIcon icon={faStar} className="inactive" />
        //   )}
        // </Fragment>
        <div></div>
      );
    })}
  </p>
);

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
};

const TestimonialItem = ({ testimonial }) => (
  <div className="testimonial-item p-3 mb-4">
    <Rating rating={testimonial.rating} />
    <p className="text-muted mb-2">{testimonial.description}</p>
    <div className="d-flex align-items-center">
      <img
        src={testimonial.author.picture}
        alt={testimonial.author.fullName}
        className="rounded-circle border"
        width="50"
      />
      <div className="ms-3">
        <h5 className="mb-0">{testimonial.author.fullName}</h5>
        <p className="mb-0 text-muted">{testimonial.author.designation}</p>
      </div>
    </div>
  </div>
);

TestimonialItem.propTypes = {
  testimonial: PropTypes.object.isRequired,
};

const TestimonialSection = () => (
  <section className="testimonial-section py-5">
    <div className="container text-center">
      <h2 className="mb-3">Community Reviews</h2>
      <p className="mb-5 text-muted">Find the right savings account for you!</p>
      <div className="row">
        {testimonials.map((testimonial, i) => (
          <div className="col-md-6 col-lg-4 mb-4" key={i}>
            <TestimonialItem testimonial={testimonial} />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default TestimonialSection;
