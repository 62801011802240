import {
    APPROVE_ADMIN_REQUEST,
    APPROVE_ADMIN_SUCCESS,
    APPROVE_ADMIN_FAILURE,
    REJECT_ADMIN_REQUEST,
    REJECT_ADMIN_SUCCESS,
    REJECT_ADMIN_FAILURE,
  } from "../../action/actionType";
  
  const initialState = {
    loading: false,
    data: {},
    error: "",
  };
  
  export const newAdminReducer = (state = initialState, action) => {
    switch (action.type) {
      case APPROVE_ADMIN_REQUEST:
      case REJECT_ADMIN_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
        };
      case APPROVE_ADMIN_SUCCESS:
      case REJECT_ADMIN_SUCCESS:
        return {
          ...state,
          loading: false,
          data: action.payload,
          error: "",
        };
      case APPROVE_ADMIN_FAILURE:
      case REJECT_ADMIN_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  