// src\components\login\otpVerificationComponent.js
import OtpInput from "otp-input-react";
import { BsFillShieldLockFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";

const OtpVerificationComponent = ({
  otp,
  setOtp,
  onVerifyOtp,
  loading,
  otpError,
}) => {
  return (
    <div className=" w-75 m-auto d-flex flex-column justify-content-center h-100 gap-md-4 gap-3">
      <h3 className="heading_three mb-0">Verify OTP</h3>
      <div>
        <label htmlFor="otp" className="label_text">
          Enter your OTP
        </label>
        <OtpInput
          value={otp}
          onChange={setOtp}
          OTPLength={6}
          otpType="number"
          disabled={false}
          autoFocus
          className="opt-container"
        />
      </div>
      <button
        onClick={onVerifyOtp}
        className=" btn border-0 outline-0 pri_bg text-white button_design sent_otp_btn"
        disabled={loading}
      >
        {loading ? (
          <div className="ms-3">
            <div
              className="spinner-border text-light spinner_class"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <span>Verify OTP</span>
        )}
      </button>
      {otpError && <p className="text-red-500 text-center">{otpError}</p>}
    </div>
  );
};

export default OtpVerificationComponent;
