export const selectedLibrary = {
  user_uid: "8789735982779294305",
  status: "submitted",
  libraryName: "Central City Library",
  libraryContact: "9876543210",
  libraryEmail: "info@centrallibrary.com",
  libraryDescription:
    "A modern library with extensive resources for all age groups.",
  libraryLocation: "123 Main Street, Central City",
  pinCode: "123456",
  district: "Central District",
  state: "Stateville",
  numberOfSheets: "150",
  libraryFacilities: ["AC", "Parking", "Water", "Coffee"],
  shifts: [
    {
      startHour: 9,
      startMinute: 0,
      startPeriod: "AM",
      endHour: 1,
      endMinute: 0,
      endPeriod: "PM",
      totalSeats: "10",
    },
    {
      startHour: 2,
      startMinute: 0,
      startPeriod: "PM",
      endHour: 6,
      endMinute: 0,
      endPeriod: "PM",
      totalSeats: "8",
    },
    {
      startHour: 7,
      startMinute: 0,
      startPeriod: "PM",
      endHour: 9,
      endMinute: 0,
      endPeriod: "PM",
      totalSeats: "5",
    },
  ],
  libraryFee: "100",
};
export const owner_details = null;

export const student_details = [
  {
    student_uuid: "8b12c9b1-5e3b-4b6f-9b36-1b7e72cdbf80",
    name: "Amit Sharma",
    mobile: "9812345678",
    whatsapp: "9812345678",
    email: "amit.sharma@example.com",
    gender: "Male",
    subscription: [
      {
        subscription_uuid: "47cbe4d2-e898-4914-b9ff-c5367358e028",
        seatNo: 3,
        shift: "Shift Three",
        startDate: "2024-09-01T18:30:00.000Z",
        endDate: "2024-09-30T18:30:00.000Z",
        fee: "2200",
      },
    ],
    address: "New Delhi, India",
    goal: "Engineering",
    memberID: "MEM1001",
  },
  {
    student_uuid: "bb2f98d1-4c77-4f60-83b4-e32e18c73951",
    name: "Suman Roy",
    mobile: "9823456789",
    whatsapp: "9823456789",
    email: "suman.roy@example.com",
    gender: "Female",
    subscription: [
      {
        subscription_uuid: "1b41d4c6-27e7-4b71-b8a8-2dbbce29a5e8",
        seatNo: 4,
        shift: "Shift One",
        startDate: "2024-06-01T18:30:00.000Z",
        endDate: "2024-06-30T18:30:00.000Z",
        fee: "2500",
      },
      {
        subscription_uuid: "6c2a32d2-3fb1-4fd8-b32a-d76f9d2c1f8a",
        seatNo: 5,
        shift: "Shift Two",
        startDate: "2024-07-01T18:30:00.000Z",
        endDate: "2024-07-31T18:30:00.000Z",
        fee: "2600",
      },
      {
        subscription_uuid: "f417d3d5-0cf0-4891-9a02-3dbd38c90a9d",
        seatNo: 6,
        shift: "Shift Three",
        startDate: "2024-08-01T18:30:00.000Z",
        endDate: "2024-08-31T18:30:00.000Z",
        fee: "2400",
      },
    ],
    address: "Kolkata, India",
    goal: "Medical",
    memberID: "MEM1002",
  },
  {
    student_uuid: "cf4b7c6d-998a-4cf7-81da-8a9e12ef06f1",
    name: "Rohit Singh",
    mobile: "9834567890",
    whatsapp: "9834567890",
    email: "rohit.singh@example.com",
    gender: "Male",
    subscription: [
      {
        subscription_uuid: "1c9d8c3f-3c99-453b-8b82-7a9c8e79f4a9",
        seatNo: 9,
        shift: "Shift One",
        startDate: "2024-05-01T18:30:00.000Z",
        endDate: "2024-05-31T18:30:00.000Z",
        fee: "2100",
      },
      {
        subscription_uuid: "b4f2a7cd-f6b9-4fbf-88fd-482b4c3d5d7c",
        seatNo: 10,
        shift: "Shift Two",
        startDate: "2024-06-01T18:30:00.000Z",
        endDate: "2024-06-30T18:30:00.000Z",
        fee: "2300",
      },
      {
        subscription_uuid: "d1e6f9b3-c9f2-4f76-9c29-e9f2d6e9a5f4",
        seatNo: 11,
        shift: "Shift Three",
        startDate: "2024-07-01T18:30:00.000Z",
        endDate: "2024-07-31T18:30:00.000Z",
        fee: "2200",
      },
    ],
    address: "Mumbai, India",
    goal: "Civil Services",
    memberID: "MEM1003",
  },
  {
    student_uuid: "e7f8g9h0-i1j2-k3l4-m5n6-o7p8q9r0s1t2",
    name: "Anjali Mehta",
    mobile: "9867890123",
    whatsapp: "9867890123",
    email: "anjali.mehta@example.com",
    gender: "Female",
    subscription: [
      {
        subscription_uuid: "5b6c7d8e-9f0g-1h2i-3j4k-5l6m7n8o9p0q",
        seatNo: 21,
        shift: "Shift One",
        startDate: "2024-02-01T18:30:00.000Z",
        endDate: "2024-02-29T18:30:00.000Z",
        fee: "2200",
      },
    ],
    address: "Hyderabad, India",
    goal: "Medical",
    memberID: "MEM1006",
  },
  {
    student_uuid: "3c4d5e6f-7g8h-9i0j-k1l2-m3n4o5p6q7r8",
    name: "Ravi Patel",
    mobile: "9878901234",
    whatsapp: "9878901234",
    email: "ravi.patel@example.com",
    gender: "Male",
    subscription: [
      {
        subscription_uuid: "5g6h7i8j-9k0l-1m2n-3o4p-5q6r7s8t9u0v",
        seatNo: 24,
        shift: "Shift One",
        startDate: "2024-01-01T18:30:00.000Z",
        endDate: "2024-01-31T18:30:00.000Z",
        fee: "2100",
      },
      {
        subscription_uuid: "7x8y9z0a-1b2c-3d4e-5f6g-7h8i9j0k1l2m",
        seatNo: 25,
        shift: "Shift Two",
        startDate: "2024-02-01T18:30:00.000Z",
        endDate: "2024-02-29T18:30:00.000Z",
        fee: "2300",
      },
    ],
    address: "Ahmedabad, India",
    goal: "Civil Services",
    memberID: "MEM1007",
  },
  {
    student_uuid: "5f6g7h8i-9j0k-1l2m-3n4o-5p6q7r8s9t0u",
    name: "Priya Sharma",
    mobile: "9889012345",
    whatsapp: "9889012345",
    email: "priya.sharma@example.com",
    gender: "Female",
    facilities: ["Library", "Parking"],
    subscription: [
      {
        subscription_uuid: "7d8e9f0g-1h2i-3j4k-5l6m-7n8o9p0q1r2s",
        seatNo: 28,
        shift: "Shift One",
        startDate: "2024-05-01T18:30:00.000Z",
        endDate: "2024-05-11T18:30:00.000Z",
        fee: "2500",
      },
      {
        subscription_uuid: "9p0q1r2s-3t4u-5v6w-7x8y-9z0a1b2c3d4e",
        seatNo: 29,
        shift: "Shift Two",
        startDate: "2024-06-01T18:30:00.000Z",
        endDate: "2024-06-30T18:30:00.000Z",
        fee: "2400",
      },
      {
        subscription_uuid: "1b2c3d4e-5f6g-7h8i-9j0k-1l2m3n4o5p6q",
        seatNo: 30,
        shift: "Shift Three",
        startDate: "2024-07-01T18:30:00.000Z",
        endDate: "2024-07-31T18:30:00.000Z",
        fee: "2600",
      },
      {
        subscription_uuid: "3d4e5f6g-7h8i-9j0k-1l2m-3n4o5p6q7r8s",
        seatNo: 31,
        shift: "Shift One",
        startDate: "2024-08-01T18:30:00.000Z",
        endDate: "2024-08-31T18:30:00.000Z",
        fee: "2500",
      },
      {
        subscription_uuid: "5f6g7h8i-9j0k-1l2m-3n4o-5p6q7r8s9t0u",
        seatNo: 32,
        shift: "Shift Two",
        startDate: "2024-09-01T18:30:00.000Z",
        endDate: "2024-09-30T18:30:00.000Z",
        fee: "2700",
      },
    ],
    address: "Indore, India",
    goal: "Banking",
    memberID: "MEM1008",
  },
  {
    student_uuid: "a2b3d6f7-5d5b-43c9-b9a7-c69f1f8b3f9d",
    name: "Priya Kapoor",
    mobile: "9845678901",
    whatsapp: "9845678901",
    email: "priya.kapoor@example.com",
    gender: "Female",
    subscription: [
      {
        subscription_uuid: "0c1d2f3e-4a5b-68c9-d7f3-ef4d5a6b7c8d",
        seatNo: 12,
        shift: "Shift One",
        startDate: "2024-04-01T18:30:00.000Z",
        endDate: "2024-04-30T18:30:00.000Z",
        fee: "2500",
      },

      {
        subscription_uuid: "8p9q0r1s-2t3u-4v5w-6x7y-8z9a0b1c2d3e",
        seatNo: 15,
        shift: "Shift One",
        startDate: "2024-08-01T18:30:00.000Z",
        endDate: "2024-09-31T18:30:00.000Z",
        fee: "2500",
      },
    ],
    address: "Chennai, India",
    goal: "MBA",
    memberID: "MEM1004",
  },
  {
    student_uuid: "d4e5f6a7-8b9c-10d2-e3f4-g5h6i7j8k9l0",
    name: "Vikas Gupta",
    mobile: "9856789012",
    whatsapp: "9856789012",
    email: "vikas.gupta@example.com",
    gender: "Male",
    subscription: [
      {
        subscription_uuid: "4q5r6s7t-8u9v-0w1x-2y3z-4a5b6c7d8e9f",
        seatNo: 19,
        shift: "Shift One",
        startDate: "2024-06-01T18:30:00.000Z",
        endDate: "2024-06-30T18:30:00.000Z",
        fee: "2400",
      },
      {
        subscription_uuid: "6e7f8g9h-0i1j-2k3l-4m5n-6o7p8q9r0s1t",
        seatNo: 20,
        shift: "Shift Two",
        startDate: "2024-08-01T18:30:00.000Z",
        endDate: "2024-09-13T18:30:00.000Z",
        fee: "2500",
      },
    ],
    address: "Pune, India",
    goal: "Arts",
    memberID: "MEM1005",
  },
  {
    student_uuid: "d1e2f3g4-h5i6-j7k8-l9m0-n1o2p3q4r5s6",
    name: "Nisha Gupta",
    mobile: "9911223344",
    whatsapp: "9911223344",
    email: "nisha.gupta@example.com",
    gender: "Female",
    subscription: [
      {
        subscription_uuid: "6a7b8c9d-0e1f-2g3h-4i5j-6k7l8m9n0o1p",
        seatNo: 33,
        shift: "Shift Two",
        startDate: "2024-08-28T18:30:00.000Z",
        endDate: "2024-08-31T18:30:00.000Z",
        fee: "2300",
      },
    ],
    address: "Chandigarh, India",
    goal: "Engineering",
    memberID: "MEM2001",
  },
  {
    student_uuid: "a1b2c3d4-e5f6-7g8h-9i0j-k1l2m3n4o5p6",
    name: "Deepak Joshi",
    mobile: "9922334455",
    whatsapp: "9922334455",
    email: "deepak.joshi@example.com",
    gender: "Male",
    subscription: [
      {
        subscription_uuid: "1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p",
        seatNo: 34,
        shift: "Shift One",
        startDate: "2024-08-25T18:30:00.000Z",
        endDate: "2024-08-31T18:30:00.000Z",
        fee: "2400",
      },
    ],
    address: "Noida, India",
    goal: "Medical",
    memberID: "MEM2002",
  },
  {
    student_uuid: "b2c3d4e5-f6g7-h8i9-j0k1-l2m3n4o5p6q7",
    name: "Aarti Patel",
    mobile: "9933445566",
    whatsapp: "9933445566",
    email: "aarti.patel@example.com",
    gender: "Female",
    subscription: [
      {
        subscription_uuid: "2a3b4c5d-6e7f-8g9h-0i1j-k2l3m4n5o6p",
        seatNo: 35,
        shift: "Shift Two",
        startDate: "2024-08-24T18:30:00.000Z",
        endDate: "2024-09-04T18:30:00.000Z",
        fee: "2500",
      },
    ],
    address: "Surat, India",
    goal: "Arts",
    memberID: "MEM2003",
  },
  {
    student_uuid: "c3d4e5f6-g7h8-i9j0-k1l2-m3n4o5p6q7r",
    name: "Manoj Kumar",
    mobile: "9944556677",
    whatsapp: "9944556677",
    email: "manoj.kumar@example.com",
    gender: "Male",
    subscription: [
      {
        subscription_uuid: "3a4b5c6d-7e8f-9g0h-1i2j-k3l4m5n6o7p",
        seatNo: 36,
        shift: "Shift One",
        startDate: "2024-08-21T18:30:00.000Z",
        endDate: "2024-09-07T18:30:00.000Z",
        fee: "2600",
      },
    ],
    address: "Bhubaneswar, India",
    goal: "Banking",
    memberID: "MEM2004",
  },
  {
    student_uuid: "d4e5f6g7-h8i9-j0k1-l2m3-n4o5p6q7r8s",
    name: "Kiran Mehta",
    mobile: "9955667788",
    whatsapp: "9955667788",
    email: "kiran.mehta@example.com",
    gender: "Female",
    subscription: [
      {
        subscription_uuid: "4a5b6c7d-8e9f-0g1h-2i3j-k4l5m6n7o8p",
        seatNo: 37,
        shift: "Shift Two",
        startDate: "2024-08-24T18:30:00.000Z",
        endDate: "2024-09-02T18:30:00.000Z",
        fee: "2700",
      },
    ],
    address: "Vadodara, India",
    goal: "MBA",
    memberID: "MEM2005",
  },
];

export const dropdownData = [
  { category: "Seats", options: [1, 2, 3, 4, 5, 6, 35] },
  { category: "Shifts", options: ["Shift One", "Shift Two", "Shift Three"] },
  { category: "Goals", options: ["IIT", "JEE", "NEET"] },
  { category: "Facilities", options: ["Library", "Canteen", "WiFi"] },
];
