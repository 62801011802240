import React from "react";
import { Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { deleteMemberDetailAction } from "../../action/newMember/newMemberDetailAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";

const ModalStudentDetailComponent = ({ student, onClose }) => {
  const {
    memberID,
    name,
    mobile,
    gender,
    goal,
    email,
    whatsapp,
    address,
    subscription,
    id,
  } = student;

  const user_uid = sessionStorage.getItem("user_uid");
  const dispatch = useDispatch();

  const handleDeny = () => {
    const successCb = () => {
      toast.success("Deleted successfully!");
      onClose();
      dispatch(fetchUserDetailAction());
    };
    const errorCb = (error) => {
      toast.error(error);
    };
    dispatch(deleteMemberDetailAction(id, user_uid, successCb, errorCb));
  };
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center justify-content-between gap-2">
          <span className="heading_four">Student Details</span>(
          <button
            className="border-0 bg-transparent d-flex align-items-center gap-1"
            onClick={() => handleDeny()}
          >
            <span className="material-symbols-outlined icon_dimention">
              Delete
            </span>
            <p className="table_row_cell text-nowrap mb-0">delete</p>
          </button>
          )
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="sec_radious border p-3 mb-3">
          <h5 className="heading_five mb-0 pri_border_bottom pb-2 mb-3 text_gray ">
            Basic Details
          </h5>
          <div>
            <p className="  text_lightGray label_text mb-2 pb-1">
              Student ID - <br />{" "}
              <span className="para_text text_gray">{memberID}</span>
            </p>
            <p className="  text_lightGray label_text mb-2 pb-1">
              Name - <br /> <span className="para_text text_gray">{name}</span>
            </p>
            <p className="  text_lightGray label_text mb-2 pb-1">
              Contact Number -
              <br /> <span className="para_text text_gray">{mobile}</span>
            </p>
            <p className="  text_lightGray label_text mb-2 pb-1">
              Whatsapp Number -
              <br /> <span className="para_text text_gray">{whatsapp}</span>
            </p>
            <p className="  text_lightGray label_text mb-2 pb-1">
              Email -
              <br /> <span className="para_text text_gray">{email}</span>
            </p>
            <p className="  text_lightGray label_text mb-2 pb-1">
              Gender -
              <br /> <span className="para_text text_gray">{gender}</span>
            </p>
            <p className="  text_lightGray label_text mb-2 pb-1">
              Goal -
              <br /> <span className="para_text text_gray">{goal}</span>
            </p>
            <p className="  text_lightGray label_text  ">
              Address -
              <br /> <span className="para_text text_gray">{address}</span>
            </p>
          </div>
        </div>
        <div className="sec_radious border p-3 mb-3">
          <h5 className="heading_five mb-0 pri_border_bottom pb-2 mb-3 text_gray ">
            Subscription Details
          </h5>
          <div className="d-flex flex-column gap-3">
            {subscription.map((sub, index) =>
              sub.library === user_uid ? (
                <div key={index} className="qr-code-container">
                  <p className="  text_lightGray label_text mb-2 pb-1">
                    Start and end Date - <br />{" "}
                    <span className="para_text text_gray d-flex gap-2">
                      {" "}
                      {new Date(sub.startDate).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}{" "}
                      <span class="material-symbols-outlined icon_dimention">
                        arrow_forward
                      </span>
                      {new Date(sub.endDate).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </span>
                  </p>
                  <p className="  text_lightGray label_text mb-2 pb-1">
                    Fee of this duration - <br />{" "}
                    <span className="para_text text_gray d-flex gap-2">
                      ₹ {sub.fee} /-
                    </span>
                  </p>
                  <p className="  text_lightGray label_text ">
                    Seat no. & Shift - <br />{" "}
                    <span className="para_text text_gray d-flex gap-2">
                      {sub.seatNo} ({sub.shift})
                    </span>
                  </p>
                </div>
              ) : null
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalStudentDetailComponent;
