import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TableStudentComponent = ({ data, onView, onEdit }) => {
  const [rowData, setRowData] = useState(data);
  const [sortedData, setSortedData] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    setRowData(data);
  }, [data]);

  useEffect(() => {
    if (rowData) {
      let sortedArray = [...rowData];
      if (sortConfig.key !== null && sortConfig.direction !== null) {
        sortedArray.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
          return 0;
        });
      }
      setSortedData(sortedArray);
    }
  }, [rowData, sortConfig]);

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    } else if (
      sortConfig.key === key &&
      sortConfig.direction === "descending"
    ) {
      direction = null;
    }
    setSortConfig({ key, direction });
  };

  const paginatedData = sortedData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View Details
    </Tooltip>
  );

  return (
    <div className=" mt-4  ">
      <div className="table-responsive pri_radious">
        <table className="table custom-table-dashboard">
          <thead>
            <tr>
              <th
                className="header-cell pri_border_bottom text-nowrap"
                onClick={() => handleSort("memberID")}
              >
                <button className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  Student ID
                  <span className="sort-icon ms-0">
                    {sortConfig.key === "memberID" &&
                    sortConfig.direction === "ascending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_down
                      </span>
                    ) : sortConfig.key === "memberID" &&
                      sortConfig.direction === "descending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_up
                      </span>
                    ) : (
                      <span className="material-symbols-outlined icon_dimention"></span>
                    )}
                  </span>
                </button>
              </th>
              <th
                className="header-cell pri_border_bottom text-nowrap"
                onClick={() => handleSort("name")}
              >
                <button className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  Name
                  <span className="sort-icon">
                    {sortConfig.key === "name" &&
                    sortConfig.direction === "ascending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_down
                      </span>
                    ) : sortConfig.key === "name" &&
                      sortConfig.direction === "descending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_up
                      </span>
                    ) : (
                      <span className="material-symbols-outlined icon_dimention"></span>
                    )}
                  </span>
                </button>
              </th>
              <th
                className="header-cell pri_border_bottom text-nowrap"
                onClick={() => handleSort("mobile")}
              >
                <button className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  Number
                  <span className="sort-icon">
                    {sortConfig.key === "mobile" &&
                    sortConfig.direction === "ascending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_down
                      </span>
                    ) : sortConfig.key === "mobile" &&
                      sortConfig.direction === "descending" ? (
                      <span className="material-symbols-outlined icon_dimention">
                        keyboard_arrow_up
                      </span>
                    ) : (
                      <span className="material-symbols-outlined icon_dimention"></span>
                    )}
                  </span>
                </button>
              </th>
              <th
                className="header-cell pri_border_bottom text-nowrap"
                onClick={() => handleSort("seatNo")}
              >
                <p className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  Seat No.
                </p>
              </th>
              <th className="header-cell pri_border_bottom text-nowrap">
                <p className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  End Date
                </p>
              </th>
              <th className="header-cell pri_border_bottom text-nowrap">
                <p className="d-flex border-0 bg-transparent m-0 p-0 fw-bold text_gray">
                  Action{" "}
                </p>{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedData && paginatedData.length > 0 ? (
              paginatedData.map((row) => (
                <tr key={row.memberID}>
                  <td className="table_row_cell text-nowrap">{row.memberID}</td>
                  <td className="table_row_cell text-nowrap">{row.name}</td>
                  <td className="table_row_cell text-nowrap">{row.mobile}</td>
                  <td className="table_row_cell text-nowrap">
                    {row.subscription?.[row.subscription.length - 1]?.seatNo} (
                    {row.subscription?.[row.subscription.length - 1]?.shift})
                  </td>
                  <td className="table_row_cell text-nowrap">
                    {new Date(
                      row.subscription?.[row.subscription.length - 1]?.endDate
                    ).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                    })}
                  </td>
                  <td className="d-flex gap-1 table_row_cell text-nowrap">
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <button
                        className="border-0 bg-transparent d-flex align-items-center gap-1"
                        onClick={() => onView(row)}
                      >
                        <span className="material-symbols-outlined icon_dimention">
                          visibility
                        </span>
                        <p className="table_row_cell text-nowrap mb-0">View</p>
                      </button>
                    </OverlayTrigger>

                    <button
                      className="border-0 bg-transparent d-flex align-items-center gap-1"
                      onClick={() => onEdit(row)}
                    >
                      <span className="material-symbols-outlined icon_dimention">
                        Edit
                      </span>
                      <p className="table_row_cell text-nowrap mb-0">Edit</p>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <nav aria-label="Page navigation">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              aria-label="Previous"
            >
              <span className="material-symbols-outlined icon_dimention">
                arrow_back_ios
              </span>
            </button>
          </li>
          {[...Array(Math.ceil(sortedData.length / rowsPerPage)).keys()].map(
            (page) => (
              <li
                key={page + 1}
                className={`page-item ${
                  currentPage === page + 1 ? "active" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => setCurrentPage(page + 1)}
                >
                  {page + 1}
                </button>
              </li>
            )
          )}
          <li
            className={`page-item ${
              currentPage === Math.ceil(sortedData.length / rowsPerPage)
                ? "disabled"
                : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() =>
                setCurrentPage((prev) =>
                  Math.min(prev + 1, Math.ceil(sortedData.length / rowsPerPage))
                )
              }
              aria-label="Next"
            >
              <span className="material-symbols-outlined icon_dimention">
                arrow_forward_ios
              </span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default TableStudentComponent;
