import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormCheck from "react-bootstrap/FormCheck";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import Select from "react-select";
import { dropdownData } from "../../util/dummyData";
import { customStyles } from "../../util/utils";
import { useDispatch } from "react-redux";
import { addSubscriptionDetailAction } from "../../action/newSubscription/newSubscriptionDetailAction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";

const seatOptions = dropdownData
  .find((item) => item.category === "Seats")
  .options.map((item) => ({ label: item, value: item }));
const shiftOptions = dropdownData
  .find((item) => item.category === "Shifts")
  .options.map((item) => ({ label: item, value: item }));

const ReNewSubModalComponent = ({ show, setShowModalRenewSub, data }) => {
  console.log("data", data);
  const dispatch = useDispatch();
  const [usePreviousData, setUsePreviousData] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const lastSubscription = data.subscription[data.subscription.length - 1];

  const initialValues = {
    startDate: startDate ? startDate.toISOString().split("T")[0] : "",
    endDate: endDate ? endDate.toISOString().split("T")[0] : "",
    seatNo: usePreviousData ? lastSubscription.seatNo : "",
    shift: usePreviousData ? lastSubscription.shift : "",
    fee: usePreviousData ? lastSubscription.fee : "",
    library: lastSubscription.library,
    member: lastSubscription.member,
  };

  const validationSchema = Yup.object({
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date().required("End Date is required"),
    seatNo: Yup.number().notRequired().required("Required"),
    shift: Yup.string().notRequired().required("Required"),
    fee: Yup.string().notRequired().required("required"),
  });

  const successCb = (data) => {
    dispatch(fetchUserDetailAction());
  };
  const errorCb = (error) => {
    console.log(error);
  };

  const handleSubmit = (values, { resetForm }) => {
    const formattedValues = {
      ...values,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
    };

    dispatch(
      addSubscriptionDetailAction(formattedValues, data?.id, successCb, errorCb)
    );
    console.log("formattedValues", formattedValues);
    resetForm();
    setShowModalRenewSub(false);
  };

  const handleCheckboxChange = (e, setFieldValue) => {
    const checked = e.target.checked;
    setUsePreviousData(checked);

    if (checked) {
      setFieldValue("seatNo", lastSubscription.seatNo);
      setFieldValue("shift", lastSubscription.shift);
      setFieldValue("fee", lastSubscription.fee);
    } else {
      setFieldValue("seatNo", "");
      setFieldValue("shift", "");
      setFieldValue("fee", "");
    }
  };

  const handleShortcut = (setFieldValue) => {
    const today = new Date();
    const oneMonthLater = new Date();
    oneMonthLater.setMonth(today.getMonth() + 1);

    setStartDate(today);
    setEndDate(oneMonthLater);

    setFieldValue("startDate", today.toISOString().split("T")[0]);
    setFieldValue("endDate", oneMonthLater.toISOString().split("T")[0]);
  };

  const handleContinue = (setFieldValue) => {
    const newStartDate = new Date(lastSubscription.endDate);
    newStartDate.setDate(newStartDate.getDate() + 1);

    const newEndDate = new Date(newStartDate);
    newEndDate.setMonth(newEndDate.getMonth() + 1);

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    setFieldValue("startDate", newStartDate.toISOString().split("T")[0]);
    setFieldValue("endDate", newEndDate.toISOString().split("T")[0]);
    setFieldValue("seatNo", lastSubscription.seatNo);
    setFieldValue("shift", lastSubscription.shift);
    setFieldValue("fee", lastSubscription.fee);
  };

  // Custom style for react-select dropdown

  return (
    <Modal
      show={show}
      onHide={() => {
        setShowModalRenewSub(false);
      }}
      backdrop="static"
      keyboard={false}
      className="renew-subscription-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="heading_four ">
          {`Renew Subscription - ${data.name}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched, resetForm }) => (
            <Form className="d-flex flex-column gap-3">
              <FormGroup controlId="dateRange">
                <FormLabel className="label_text">
                  Select duration of subscription
                </FormLabel>
                <div className="d-flex gap-3">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                      setFieldValue(
                        "startDate",
                        date ? date.toISOString().split("T")[0] : ""
                      );
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    className={`form-control input_feild ${
                      errors.startDate && touched.startDate ? "is-invalid" : ""
                    }`}
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                      setFieldValue(
                        "endDate",
                        date ? date.toISOString().split("T")[0] : ""
                      );
                    }}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    className={`form-control input_feild ${
                      errors.endDate && touched.endDate ? "is-invalid" : ""
                    }`}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <FormLabel className="label_text">Seat No.</FormLabel>
                <Select
                  options={seatOptions}
                  value={
                    values.seatNo
                      ? seatOptions.find(
                          (option) => option.value === values.seatNo
                        )
                      : null
                  }
                  className="formBodyControlModal"
                  placeholder="Select Seat Number"
                  isSearchable
                  onChange={(option) => setFieldValue("seatNo", option.value)}
                  styles={customStyles}
                />
                {errors.seatNo && touched.seatNo && (
                  <div className="text-danger">{errors.seatNo}</div>
                )}
              </FormGroup>

              <FormGroup>
                <FormLabel className="label_text">Shift</FormLabel>
                <Select
                  options={shiftOptions}
                  value={
                    values.shift
                      ? shiftOptions.find(
                          (option) => option.value === values.shift
                        )
                      : null
                  }
                  className="formBodyControlModal"
                  placeholder="Select Shift"
                  onChange={(option) => setFieldValue("shift", option.value)}
                  styles={customStyles}
                />
                {errors.shift && touched.shift && (
                  <div className="text-danger">{errors.shift}</div>
                )}
              </FormGroup>

              <FormGroup controlId="fee">
                <FormLabel className="label_text">Fee</FormLabel>
                <Field
                  name="fee"
                  type="text"
                  className={`form-control input_feild ${
                    errors.fee && touched.fee ? "is-invalid" : ""
                  }`}
                />
              </FormGroup>

              <div className="d-flex flex-md-row flex-column gap-md-auto gap-3 align-items-center justify-content-between my-1 py-3 border-top border-bottom">
                <FormGroup controlId="usePreviousData ">
                  <FormCheck
                    type="checkbox"
                    label="Same as previous data"
                    checked={usePreviousData}
                    onChange={(e) => handleCheckboxChange(e, setFieldValue)}
                  />
                </FormGroup>
                <div className="d-flex gap-2">
                  <Button
                    className="pri_radious btn-primary border-none border-0 mt-0 me-2 select_duration_btn"
                    onClick={() => handleShortcut(setFieldValue)}
                  >
                    1 Month from Today
                  </Button>
                  <Button
                    className="pri_radious btn-success border-none border-0 mt-0 me-2 select_duration_btn"
                    onClick={() => handleContinue(setFieldValue)}
                  >
                    Continue
                  </Button>
                </div>
              </div>

              <div className="col-12 d-flex border-0 gap-2 outline-0 justify-content-between my-2 ">
                <Button
                  className="button_design w-50"
                  variant="outline"
                  onClick={() => {
                    setShowModalRenewSub(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>

                <Button
                  className="pri_bg button_design w-50 shadow-none"
                  variant="secondary"
                  type="submit"
                >
                  Renew Seat
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ReNewSubModalComponent;
