// src/reducer/newUser/newUserDetailReducer.js
import {
  ADD_USER_DETAIL_REQUEST,
  ADD_USER_DETAIL_SUCCESS,
  ADD_USER_DETAIL_FAILURE,
  FETCH_USER_DETAIL_REQUEST,
  FETCH_USER_DETAIL_SUCCESS,
  FETCH_USER_DETAIL_FAILURE,
  FETCH_OPTION_DETAIL_REQUEST,
  FETCH_OPTION_DETAIL_SUCCESS,
  FETCH_OPTION_DETAIL_FAILURE,
} from "../../action/actionType";

const initialState = {
  loading: false,
  data: {},
  optionData: {},
  error: "",
};

export const newUserDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_DETAIL_REQUEST:
    case FETCH_USER_DETAIL_REQUEST:
    case FETCH_OPTION_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ADD_USER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_USER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: "",
      };
    case FETCH_OPTION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        optionData: action.payload,
        error: "",
      };
    case ADD_USER_DETAIL_FAILURE:
    case FETCH_USER_DETAIL_FAILURE:
    case FETCH_OPTION_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
