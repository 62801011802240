import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAsv4YpuE_t01kYpz6FIhDizS5WHEGEUfg",
  authDomain: "librify-in.firebaseapp.com",
  databaseURL:
    "https://librify-in-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "librify-in",
  storageBucket: "librify-in.appspot.com",
  messagingSenderId: "13438426729",
  appId: "1:13438426729:web:9d170ddc4c54cd25830cf8",
  measurementId: "G-62NQ8QY2WH",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
onAuthStateChanged(auth, (user) => {
  if (user) {
    const uid = user.uid;
    sessionStorage.setItem("user_uid", uid);
  } else {
    sessionStorage.removeItem("user_uid");
  }
});
export default app;
