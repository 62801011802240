import React from "react";

const WhyChooseUs = () => {
  return (
    <div className="why-choose-us pt-5 pb-5">
      <div className="container">
        <div className="section-head text-center mb-4">
          <h4>
            <span>Why Choose</span> Us?
          </h4>
          <p className="section-subtitle">
            When you choose us, you'll benefit from over 10 years of experience
            in Web Development, SEO.
          </p>
        </div>
        <div className="ag-format-container">
          <div className="ag-courses_box p-0">
            <div className="ag-courses_item">
              <div className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>

                <div className="ag-courses-item_title">Management Suite</div>

                <div className="ag-courses-item_date-box">
                Manage bookings, attendance, usage, and payments seamlessly in one platform—no extra tools needed.
                </div>
              </div>
            </div>

            <div className="ag-courses_item">
              <div className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>

                <div className="ag-courses-item_title">Enhance Engagement</div>

                <div className="ag-courses-item_date-box">
                Send real-time alerts for bookings, reminders, offers, and updates to keep users engaged.
                </div>
              </div>
            </div>

            <div className="ag-courses_item">
              <div className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>

                <div className="ag-courses-item_title">Cost-Effective</div>

                <div className="ag-courses-item_date-box">
                Affordable plans for all reading rooms with no hidden fees,ensuring great value and transparent pricing.
                </div>
              </div>
            </div>

            <div className="ag-courses_item">
              <div className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>

                <div className="ag-courses-item_title">Cloud-Based Accessibility</div>

                <div className="ag-courses-item_date-box">
                Manage and monitor your reading room  operations from anywhere with our cloud-based software.
                </div>
              </div>
            </div>

            <div className="ag-courses_item">
              <div className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>

                <div className="ag-courses-item_title">Flexible and Adaptive</div>

                <div className="ag-courses-item_date-box">
                Our software evolves with you, offering regular updates and customizable amenities.
                </div>
              </div>
            </div>

            <div className="ag-courses_item">
              <div className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>

                <div className="ag-courses-item_title">Marketing and Outreach </div>
                <div className="ag-courses-item_date-box">
                SEO Listings: Improve visibility and attract more members through relevant search results.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
