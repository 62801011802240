import React from "react";

const features = [
  {
    icon: "fa-globe",
    title: "User-Centric Design",
    description:
    "We create and refine our platform based on user feedback to serve reading room owners and students",
    bgColor: "rgba(247, 198, 5, 0.2)",
  },
  {
    icon: "fa-anchor",
    title: "Innovation",
    description:
    "Cutting-edge technologies drive innovative, efficient reading room management solutions",
    bgColor: "rgba(255, 77, 28, 0.15)",
  },
  {
    icon: "fa-hourglass-half",
    title: "Collaboration",
    description:
      "The best ideas come from collaboration, creating a product that truly makes a difference",
    bgColor: "rgba(0, 147, 38, 0.15)",
  },
  {
    icon: "fa-database",
    title: "Continuous Improvement",
    description:
      "Always seeking improvement, the focus is on new features, better support, and enhanced security",
    bgColor: "rgba(0, 108, 255, 0.15)",
  },
  {
    icon: "fa-upload",
    title: "Community Engagement",
    description:
      "Valuing community, the platform fosters interaction through events, forums, and collaborative features",
    bgColor: "rgba(146, 39, 255, 0.15)",
  },
  {
    icon: "fa-camera",
    title: "Empowerment",
    description: "Empowering users with tools and insights to enhance their control and decision-making capabilities",
    bgColor: "rgba(23, 39, 246, 0.15)",
  }
];

const AboutPageComponent = () => (
  <div>
    <div className="hero-section">
      <h1 className="hero-title">About Us</h1>
      <p className="hero-subtitle">Our Journey, Team, and Values</p>
    </div>

    <div className="container m-auto row about-us-our-team my-5 py-5 gap-y-3">
      <div className=" col-12 section-head text-center mb-4 mt-5">
        <h4>
          <span>Our</span> Team
        </h4>
        <p className="section-subtitle">
          When Choose Us
        </p>
      </div>
      <div className="col-md-4 col-12">
        <article className="w-100">
          <figure className="w-100">
            <img src="https://assets.codepen.io/605876/person.png" alt=""></img>
          </figure>
          <h2 className="name_about">Deepak Shukla</h2>
          <p className="name_para">
          MNIT Jaipur graduate and Software Engineer at Deloitte, founded Gao Se and runs a YouTube channel with 20,000+ subscribers, sharing tips for engineering students.
         </p>
        </article>
      </div>
      <div className="col-md-4 col-12">
        <article className="w-100">
          <figure className="w-100">
            <img src="https://assets.codepen.io/605876/person.png" alt=""></img>
          </figure>
          <h2 className="name_about">Rishi Sethia</h2>
          <p className="name_para">
          IIT Roorkee graduate and Software Engineer at Jaguar, brings technical expertise to our team, driving platform innovation and refinement.
          </p>
        </article>
      </div>
      <div className="col-md-4 col-12">
        <article className="w-100">
          <figure className="w-100">
            <img src="https://assets.codepen.io/605876/person.png" alt=""></img>
          </figure>
          <h2 className="name_about">Chiradeep Saha Roy</h2>
          <p className="name_para">
          IIT Roorkee graduate and Oracle engineer, brings technical expertise to enhance our platform.
          </p>
        </article>
      </div>
      <div className="col-md-4 col-12">
        <article className="w-100">
          <figure className="w-100">
            <img src="https://assets.codepen.io/605876/person.png" alt=""></img>
          </figure>
          <h2 className="name_about">Mayank Shukla</h2>
          <p className="name_para">
            IIT Roorkee graduate and former TruMinds developer, brings technical skill and innovation to our platform.
          </p>
        </article>
      </div>
    </div>

    <div className="container section-custom my-md-5 py-5">
      <div className="row">
        <div className=" col-12 section-head text-center mb-4">
          <h4>
            <span>Our</span> Values
          </h4>
          <p className="section-subtitle">
            Why Choose Us
          </p>
        </div>
        <div className="row m-auto">
          {features.map((feature, index) => (
            <div className="col-lg-4 col-md-6 mb-4" key={index}>
              <div className="feature-item">
                <span
                  className="icon"
                  style={{ backgroundColor: feature.bgColor }}
                >
                  <i className={`fa ${feature.icon}`}></i>
                </span>
                <h6>{feature.title}</h6>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default AboutPageComponent;
