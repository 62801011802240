import React, { useState } from "react";
import bg_main from "../../asset/image/Frame 1.svg";
import TestimonialSection from "../../components/landingPage/testimonialComponent";
import WhyChooseUs from "../../components/landingPage/whyChooseUs";
import FAQSection from "../../components/landingPage/faqComponent";
import FooterLanding from "../../components/landingPage/footer";
import "../../index";
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const [activeTab, setActiveTab] = useState("home");
  const navigate = useNavigate();
  const handleNavigateJoin = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="landing-page landing-page-css">
        <div className="main-section-landing position-relative container">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="title animate__animated animate__fadeInDown head-text-landing mb-2">
              Simplifying Reading Room Management
            </h1>
            <p className="para   animate__animated animate__fadeInUp para-head-text-landing">
              One-stop digital solution of your Library
            </p>
            <button
              className="get-started-btn animate__animated animate__fadeInUp"
              data-wow-delay="0.4s"
              data-toggle="modal"
              data-target="#requestDemoModal"
              onClick={handleNavigateJoin}
            >
              Get Started
            </button>
          </div>
          <div className="d-flex align-items-center">
            <img
              src={bg_main}
              className="img-landing m-auto"
              alt="f-b-banner"
            ></img>
          </div>
        </div>
        <div className="tabs-with-feature-container">
          <div className="row align-items-center container m-auto gap-3 gap-md-0">
            <div
              className="nav flex-column nav-pills col-md-7 col-12 px-0"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <div
                className={`${
                  activeTab === "home" ? "active-container" : ""
                } p-4`}
              >
                <h3 className="feature-heading-landing text-white">
                  Analytics Dashboard
                </h3>
                <p className="feature-para-landing text-white">
                  Track occupancy, peak times, and revenue to optimize your
                  reading room.
                </p>
                <button
                  className={`active-btn-landing ${
                    activeTab === "home" ? "active" : ""
                  }`}
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  aria-controls="v-pills-home"
                  aria-selected={activeTab === "home"}
                  onClick={() => setActiveTab("home")}
                >
                  <span>View feature</span>
                  <i className="bi bi-arrow-right"></i>
                </button>
              </div>

              <div
                className={`${
                  activeTab === "profile" ? "active-container" : ""
                } p-4`}
              >
                <h3 className="feature-heading-landing text-white">
                  QR Scanner and Messaging System
                </h3>
                <p className="feature-para-landing text-white">
                  Onboard students, track attendance, and send instant
                  notifications.
                </p>
                <button
                  className={`active-btn-landing ${
                    activeTab === "profile" ? "active" : ""
                  }`}
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                  type="button"
                  aria-controls="v-pills-profile"
                  aria-selected={activeTab === "profile"}
                  onClick={() => setActiveTab("profile")}
                >
                  View feature
                </button>
              </div>
            </div>

            <div
              className="tab-content col-md-5 col-12"
              id="v-pills-tabContent"
            >
              <div
                className={`tab-pane fade ${
                  activeTab === "home" ? "show active" : ""
                }`}
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <img
                  className="w-100 image-feature-landing"
                  src="https://img.freepik.com/free-photo/digital-art-ai-technology-background_23-2151719575.jpg?t=st=1723740984~exp=1723744584~hmac=d8e48a602c6d01bd7a1ac522da73abbc57278ab2ab4316afb496e1bad642d454&w=740"
                  alt=""
                />
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === "profile" ? "show active" : ""
                }`}
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <img
                  className="w-100 image-feature-landing"
                  src="https://img.freepik.com/free-photo/digital-art-ai-technology-background_23-2151719563.jpg?t=st=1723740920~exp=1723744520~hmac=0e6c424019728963b53a0e9a5320502c155430699b2ee77e215dc7bdd13c7530&w=740"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <WhyChooseUs />
        <TestimonialSection />
        <div className="bg-white py-5 faq-container border-top">
          <FAQSection />
        </div>
        <FooterLanding />
      </div>
    </>
  );
};

export default LandingPage;
