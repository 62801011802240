export const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "48px",
    height: "48px",
    borderRadius: "8px",
    borderColor: "#ced4da",
    boxShadow: "none",
    padding: "0px 8px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "48px",
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "48px",
  }),
};

// dashboard locations

export const dashboardLocation = ["/dashboard", "/students", "/profile"];
