import {
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAILURE,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  AUTH_CHECK_REQUEST,
} from "../actionType";
import { db, auth } from "../../firebase/firebase.auth.config";
import { doc, setDoc, getDoc } from "firebase/firestore";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { toast } from "react-hot-toast";

export const sendOtp = (phoneNumber) => async (dispatch) => {
  dispatch({ type: SEND_OTP_REQUEST });

  try {
    const appVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      auth
    );
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      appVerifier
    );
    window.confirmationResult = confirmationResult;

    dispatch({ type: SEND_OTP_SUCCESS });
    toast.success("OTP sent successfully!");
  } catch (error) {
    dispatch({ type: SEND_OTP_FAILURE, payload: error.message });
    toast.error("Failed to send OTP!");
  }
};

export const verifyOtp = (otp) => async (dispatch) => {
  dispatch({ type: VERIFY_OTP_REQUEST });

  try {
    const result = await window.confirmationResult.confirm(otp);
    const user = result.user;
    sessionStorage.setItem("userUid", user.uid);

    const userDoc = doc(db, "owner", user.uid);
    const docSnap = await getDoc(userDoc);

    const authUser = auth.currentUser;

    if (!docSnap.exists()) {
      const defaultData = {
        user: { status: false, phoneNumber: authUser.phoneNumber },
        library: { status: false },
        members: [],
        pending: [],
        approval: false,
        reject: false,
        id: user.uid,
        options: [],
      };
      await setDoc(userDoc, defaultData);
    }

    dispatch({ type: VERIFY_OTP_SUCCESS, payload: user });
    toast.success("OTP verified successfully!");
  } catch (error) {
    dispatch({ type: VERIFY_OTP_FAILURE, payload: error.message });
    toast.error("Failed to verify OTP!");
  }
};

export const logoutAction = (callback) => async (dispatch) => {
  dispatch({ type: LOGOUT_REQUEST });

  try {
    await signOut(auth);
    toast.success("Logged out successfully!");

    dispatch({ type: LOGOUT_SUCCESS });
    sessionStorage.clear();
    if (callback) callback();
  } catch (error) {
    dispatch({ type: LOGOUT_FAILURE, payload: error.message });
    toast.error("Failed to log out!");
  }
};

export const verifyAuthStatus = () => (dispatch) => {
  dispatch({ type: AUTH_CHECK_REQUEST });

  auth.onAuthStateChanged((user) => {
    if (user) {
      dispatch({ type: VERIFY_OTP_SUCCESS, payload: user });
    } else {
      dispatch({ type: LOGOUT_SUCCESS });
    }
  });
};
