import React, { useState } from "react";

import LibraryDetailForm from "../../components/profile/libraryDetailForm";
import PersonalDetailFrom from "../../components/profile/personalDetailFrom";
import { calculateShiftDuration } from "../../util/utilsFunction";
import { fetchUserDetailAction } from "../../action/newUser/newUserDetailAction";
import { useDispatch } from "react-redux";
import Loader from "../../sharedCOmponent/loader";

function ProfileScreen({
  owner_details,
  library_details,
  loading,
  error,
  newUserDetail,
}) {
  console.log("newUserDetail", newUserDetail);

  const dispatch = useDispatch();
  const [showPersonalDetail, setShowPersonalDetail] = useState(false);
  const [showLibraryDetail, setShowLibraryDetail] = useState(false);
  const successCb = (data) => {
    dispatch(fetchUserDetailAction());
  };

  const errorCb = (error) => {
    console.log(error);
  };

  sessionStorage.setItem("library_name", library_details?.libraryName);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <div className="text-alert text-center">{error}</div>
      ) : (
        <div className="pe-md-5 ">
          <h1 className="heading_three mt-md-4 mb-3">
            Owner and Library Details
          </h1>
          {!newUserDetail.approval &&
            library_details?.status &&
            owner_details?.status && (
              <div class="alert alert-success" role="alert">
                <strong>Thank you for your request!</strong> We’ve received it
                and our team will reach out to you soon for verification.
              </div>
            )}

          <div className="p-4 mb-5 bg-white pri_radious profile_detail_container">
            <div className="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
              <h4 className="heading_four text_black">Personal Details</h4>
              <button
                type="button"
                className="button_design"
                onClick={() => setShowPersonalDetail(true)}
              >
                <span className="material-symbols-outlined">draw</span>
              </button>
            </div>
            {owner_details?.status ? (
              <div className="row gap-3">
                <div className="col-md-5 col-12">
                  <p className="label_text">Owner Name</p>
                  <div className="button_design border text_gray ">
                    {owner_details.ownerName}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">Contact Number</p>
                  <div className="button_design border text_gray ">
                    {" "}
                    {owner_details.phoneNumber}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">Email ID</p>
                  <div className="button_design border text_gray ">
                    {owner_details.email}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">WhatsApp No.</p>
                  <div className="button_design border text_gray ">
                    {owner_details.whatsappNumber}
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center my-5">
                <button
                  className="button_design pri_bg text-white text-center"
                  onClick={() => setShowPersonalDetail(true)}
                >
                  Add Personal/Owner Details
                </button>
              </div>
            )}
          </div>
          <div className="p-4 mb-5 bg-white pri_radious profile_detail_container">
            <div className="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
              <h4 className="heading_four text_black">Library Details</h4>
              <button
                type="button"
                className="button_design"
                onClick={() => setShowLibraryDetail(true)}
              >
                <span className="material-symbols-outlined">draw</span>
              </button>
            </div>
            {library_details?.status ? (
              <div className="row gap-3">
                <div className="col-md-5 col-12">
                  <p className="label_text">Library Name</p>
                  <div className="button_design border text_gray ">
                    {library_details.libraryName}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">Contact Number</p>
                  <div className="button_design border text_gray ">
                    {library_details.libraryContact}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">Email ID</p>
                  <div className="button_design border text_gray ">
                    {library_details.libraryEmail}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">Location</p>
                  <div className="button_design border text_gray ">
                    {library_details.libraryLocation}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">Pin Code</p>
                  <div className="button_design border text_gray ">
                    {library_details.pinCode}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">District</p>
                  <div className="button_design border text_gray ">
                    {library_details.district}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">State</p>
                  <div className="button_design border text_gray ">
                    {library_details.state}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">Description</p>
                  <div className="button_design border text_gray ">
                    {library_details.libraryDescription}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">Library Facilities</p>
                  <div className="button_design border text_gray ">
                    {library_details.libraryFacilities?.join(", ")}
                  </div>
                </div>
                <div className="col-md-5 col-12">
                  <p className="label_text">Library Fee</p>
                  <div className="button_design border text_gray ">
                    {library_details.libraryFee}
                  </div>
                </div>
                {library_details?.shifts?.map((shift, index) => {
                  const duration = calculateShiftDuration(
                    shift.startHour,
                    shift.startMinute,
                    shift.startPeriod,
                    shift.endHour,
                    shift.endMinute,
                    shift.endPeriod
                  );
                  return (
                    <div className="col-md-5 col-12" key={index}>
                      <div key={index} className="qr-code-container">
                        <div className="row">
                          <p className="  text_lightGray label_text mb-2 pb-1 col-6">
                            Shift name - <br />{" "}
                            <span className="para_text text_gray d-flex gap-2">
                              Shift-{index + 1}
                            </span>
                          </p>{" "}
                          <p className="  text_lightGray label_text col-6">
                            Total Seats - <br />{" "}
                            <span className="para_text text_gray d-flex gap-2">
                              {shift.totalSeats}
                            </span>
                          </p>
                        </div>
                        <p className="  text_lightGray label_text mb-2 pb-1">
                          Shift Duration - <br />{" "}
                          <span className="para_text text_gray d-flex gap-2">
                            {`${shift.startHour}:${shift.startMinute
                              .toString()
                              .padStart(2, "0")} ${shift.startPeriod}`}
                            <span class="material-symbols-outlined icon_dimention">
                              arrow_forward
                            </span>
                            {`${shift.endHour}:${shift.endMinute
                              .toString()
                              .padStart(2, "0")} ${shift.endPeriod}`}
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex justify-content-center my-5">
                <button
                  className="button_design pri_bg text-white text-center"
                  onClick={() => setShowLibraryDetail(true)}
                >
                  Add Library Details
                </button>
              </div>
            )}
          </div>

          <LibraryDetailForm
            show={showLibraryDetail}
            handleClose={() => setShowLibraryDetail(false)}
            selectedLibrary={library_details}
            successCb={successCb}
            errorCb={errorCb}
          />
          <PersonalDetailFrom
            show={showPersonalDetail}
            handleClose={() => setShowPersonalDetail(false)}
            selectedPerson={owner_details}
            successCb={successCb}
            errorCb={errorCb}
          />
        </div>
      )}
    </>
  );
}

export default ProfileScreen;
