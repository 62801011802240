import {
  ADD_USER_DETAIL_REQUEST,
  ADD_USER_DETAIL_SUCCESS,
  ADD_USER_DETAIL_FAILURE,
  FETCH_USER_DETAIL_REQUEST,
  FETCH_USER_DETAIL_SUCCESS,
  FETCH_USER_DETAIL_FAILURE,
  FETCH_OPTION_DETAIL_REQUEST,
  FETCH_OPTION_DETAIL_SUCCESS,
  FETCH_OPTION_DETAIL_FAILURE,
} from "../../action/actionType";
import { auth, db } from "../../firebase/firebase.auth.config";
import { doc, setDoc, getDoc } from "firebase/firestore";
import toast from "react-hot-toast";

const fetchUserDetailRequest = () => ({
  type: FETCH_USER_DETAIL_REQUEST,
});

const fetchUserDetailSuccess = (userDetails) => ({
  type: FETCH_USER_DETAIL_SUCCESS,
  payload: userDetails,
});

const fetchUserDetailFailure = (error) => ({
  type: FETCH_USER_DETAIL_FAILURE,
  payload: error,
});

const fetchOptionDetailRequest = () => ({
  type: FETCH_OPTION_DETAIL_REQUEST,
});

const fetchOptionDetailSuccess = (optionDetails) => ({
  type: FETCH_OPTION_DETAIL_SUCCESS,
  payload: optionDetails,
});

const fetchOptionDetailFailure = (error) => ({
  type: FETCH_OPTION_DETAIL_FAILURE,
  payload: error,
});

const addUserDetailRequest = () => ({
  type: ADD_USER_DETAIL_REQUEST,
});

const addUserDetailSuccess = (userDetails) => ({
  type: ADD_USER_DETAIL_SUCCESS,
  payload: userDetails,
});

const addUserDetailFailure = (error) => ({
  type: ADD_USER_DETAIL_FAILURE,
  payload: error,
});

export const fetchUserDetailAction = () => async (dispatch) => {
  dispatch(fetchUserDetailRequest());
  try {
    const userUid = auth?.currentUser?.uid;

    const userDoc = doc(db, "owner", userUid);
    const docSnap = await getDoc(userDoc);

    const authUser = auth.currentUser;

    if (!docSnap.exists()) {
      const defaultData = {
        user: { status: false, phoneNumber: authUser.phoneNumber },
        library: { status: false },
        members: [],
        pending: [],
        approval: false,
        reject: false,
        id: userUid,
        options: [],
      };

      await setDoc(userDoc, defaultData);

      dispatch(fetchUserDetailSuccess({ ...defaultData }));
    } else {
      let data = docSnap.data();
      let res = [];

      for (let id of data.members) {
        const memberDoc = doc(db, "member", id);
        const memDocSnap = await getDoc(memberDoc);
        const memberData = memDocSnap.data();

        let sub = [];
        if (memberData?.subscription) {
          for (let subId of memberData.subscription) {
            const subDoc = doc(db, "subscription", subId);
            const subDocSnap = await getDoc(subDoc);
            sub.push(subDocSnap.data());
          }
        }
        memberData.subscription = sub;
        res.push(memberData);
      }
      data.members = res;
      res = [];
      for (let id of data.pending) {
        const memberDoc = doc(db, "member", id);
        const memDocSnap = await getDoc(memberDoc);
        const memberData = memDocSnap.data();
        var sub = [];
        for (let subId of memberData.subscription) {
          const subDoc = doc(db, "subscription", subId);
          const subDocSnap = await getDoc(subDoc);
          sub.push(subDocSnap.data());
        }
        memberData.subscription = sub;
        res.push(memberData);
      }
      data.pending = res;
      let resDropdown = new Array(3);
      let arr = [];
      let count = 0;
      let nums = [
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
        "Ten",
      ];
      while (count < data.library.shifts?.length) {
        arr.push("Shift " + nums[count]);
        count++;
      }
      resDropdown[0] = { category: "Shifts", options: arr };
      resDropdown[1] = {
        category: "Goals",
        options: ["UPSC", "IIT", "JEE", "NEET"],
      };
      resDropdown[2] = {
        category: "Facilities",
        options: data.library.libraryFacilities,
      };
      data.options = resDropdown;
      dispatch(fetchUserDetailSuccess({ ...data }));
    }
  } catch (error) {
    dispatch(fetchUserDetailFailure(error.message));
  }
};

export const fetchOptionDetailAction = (userId) => async (dispatch) => {
  dispatch(fetchOptionDetailRequest());
  try {
    const userDoc = doc(db, "owner", userId);
    const docSnap = await getDoc(userDoc);
    const data = docSnap.data();
    let resDropdown = new Array(3);
    let arr = [];
    let count = 0;
    let nums = [
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
    ];
    while (count < data.library.shifts?.length) {
      arr.push("Shift " + nums[count]);
      count++;
    }
    resDropdown[0] = { category: "Shifts", options: arr };
    resDropdown[1] = {
      category: "Goals",
      options: ["UPSC", "IIT", "JEE", "NEET"],
    };
    resDropdown[2] = {
      category: "Facilities",
      options: data.library.libraryFacilities,
    };
    dispatch(fetchOptionDetailSuccess(resDropdown));
  } catch (error) {
    dispatch(fetchOptionDetailFailure(error.message));
  }
};

export const addUserDetailAction =
  (userDetails, successCb, errorCb) => async (dispatch) => {
    dispatch(addUserDetailRequest());
    try {
      const userUid = auth?.currentUser?.uid;

      const userDoc = doc(db, "owner", userUid);
      const docSnap = await getDoc(userDoc);
      var docData = docSnap.data();
      docData.user = { ...userDetails, status: true };
      docData.reject = false;
      await setDoc(userDoc, docData);
      dispatch(addUserDetailSuccess(userDetails));
      successCb(userDetails);
      toast.success("Detail added successfully!");
    } catch (error) {
      errorCb(error);
      dispatch(addUserDetailFailure(error.message));
    }
  };
