import {
  ADD_SUBSCRIPTION_DETAIL_REQUEST,
  ADD_SUBSCRIPTION_DETAIL_SUCCESS,
  ADD_SUBSCRIPTION_DETAIL_FAILURE,
} from "../../action/actionType";
import { db, auth } from "../../firebase/firebase.auth.config";
import { doc, setDoc, getDoc, addDoc, collection } from "firebase/firestore";
import { toast } from "react-hot-toast";

const addSubscriptionDetailRequest = () => ({
  type: ADD_SUBSCRIPTION_DETAIL_REQUEST,
});

const addSubscriptionDetailSuccess = (subscriptionDetails) => ({
  type: ADD_SUBSCRIPTION_DETAIL_SUCCESS,
  payload: subscriptionDetails,
});

const addSubscriptionDetailFailure = (error) => ({
  type: ADD_SUBSCRIPTION_DETAIL_FAILURE,
  payload: error,
});

export const addSubscriptionDetailAction =
  (subscriptionDetails, memberId, successCb, errorCb) => async (dispatch) => {
    dispatch(addSubscriptionDetailRequest());
    try {
      const user = auth.currentUser;

      // Get member details
      const memberDoc = doc(db, "member", memberId);
      const memberDocSnap = await getDoc(memberDoc);
      var memberDocData = memberDocSnap.data();

      // Add new subscription
      subscriptionDetails.member = memberId;
      subscriptionDetails.library = user.uid;
      const subDocRef = await addDoc(
        collection(db, "subscription"),
        subscriptionDetails
      );

      // Push this subscription to the member
      memberDocData.subscription.push(subDocRef.id);
      await setDoc(memberDoc, memberDocData);

      // Add subscription id to subscription
      const subDoc = doc(db, "subscription", subDocRef.id);
      const subDocSnap = await getDoc(subDoc);
      var subData = {...subDocSnap.data()};
      subData.id = subDocRef.id;
      await setDoc(subDoc, subData);

      // Dispatch success
      successCb(subscriptionDetails);
      toast.success("Logged out successfully!");
      dispatch(addSubscriptionDetailSuccess(subscriptionDetails));
    } catch (error) {
      errorCb(error);
      dispatch(addSubscriptionDetailFailure(error.message));
      toast.error("Failed to add details");
    }
  };
