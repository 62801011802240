import React from "react";

export default function FooterLanding() {
  return (
    <footer className="text-center text-white footer-container-div">
      <div className="p-4 pb-0">
        <section className="d-flex justify-content-center align-items-center">
          <span className="me-3">Register for free</span>
          <a
            href="#"
            className="get-started-btn animate__animated animate__fadeInUp m-0 p-0 bg-white text-black"
            data-wow-delay="0.4s"
            data-toggle="modal"
            data-target="#requestDemoModal"
          >
            Get Started
          </a>
        </section>
      </div>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
      >
        © 2024 Copyright:
        <a className="text-white" href="#">
          Librify.com
        </a>
      </div>
    </footer>
  );
}
